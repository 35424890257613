import React, { useRef, useEffect } from 'react';
import Styles from './DeletedPopup.module.css';

export default function DeletedPopup({
  show,
  handleClickDone,
  handleClicCancel,
  observation,
  closePopup,
  item,
  relate = false,
  message,
}) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopup(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div >
      {show && (
        <div className={Styles.background} ref={wrapperRef}>
          <div className={Styles.container}>
            <h1 className={Styles.title}>
              {relate
                ? `
                Weet je zeker dat je deze observatie weg wilt gooien "${observation.competenceTitle}"?`
                : message}
            </h1>
            <div className={Styles.buttons}>
              {relate ? (
                <button className={Styles.done} onClick={() => handleClickDone(observation)}>
                  Yes
                </button>
              ) : (
                <button className={Styles.done} onClick={() => handleClickDone(item)}>
                  Yes
                </button>
              )}
              
              <button className={Styles.cancel} onClick={handleClicCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
