import React from "react";
import { Form, Field } from "react-final-form";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container, Button, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: "100%",
    padding: "20px",
    border: "1px solid #ccc",
    boxShadow: "2px 2px 5px rgba(0,0,0,0.3)",
  },
  underlineInput: {
    "& .MuiInputBase-root": {
      "& input": {
        boxShadow: "none",
        borderBottom: "none",
        paddingLeft: "15px",
        marginBottom: 0,

        "&:focus": {
          borderBottom: "none !important",
          boxShadow: "none !important",
        },
      },
      "&:before": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&:before": {
          borderBottom: "none",
        },
      },
    },
  },
}));

export default function Forma({ checkPass }) {
  const classes = useStyles();

  const onSubmit = (values) => {
    checkPass(values.password);
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Typography color="primary" align="center" variant="h2">
              Secret room
            </Typography>
            <Field name="password">
              {({ input, meta }) => (
                <div>
                  <TextField
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    {...input}
                    classes={{
                      root: classes.underlineInput,
                    }}
                    required
                    variant="outlined"
                    margin="normal"
                    id="password"
                    label="Введите пароль"
                    type="password"
                    fullWidth
                  />
                  <Button
                    type="submit"
                    disabled={submitting}
                    variant="contained"
                    color="primary"
                  >
                    Войти
                  </Button>
                </div>
              )}
            </Field>
          </form>
        )}
      />
    </Container>
  );
}