import React, { useState } from "react";
import Styles from "./OurPage.module.css";
import Forma from "./Forma";
import AddTeacher from "./AddTeacher";
import AddStudent from "./AddStudent";
import AddAll from "./AddAll";
import api from "../../api/APIHelper";

const OurPage = () => {
  const [checkPassword, setCheckPassword] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [formStudents, setFormStudents] = useState({})

  const checkPass = (password) => {
    const secretNumber = 1;
    if (Number(password) === secretNumber) {
      setCheckPassword(false);
    } else {
      alert("Неверный пароль");
    }
  };

  const onAddTeachers = (addTeachers) => {
    setFormValues(addTeachers);
  };
  const sendTeachers = () => {
    api.postTeachers(formValues.data);
  }
  const onAddStudents = (addStudents) => {
    setFormStudents(addStudents);
  };
  const sendStudents = () => {
    api.postStudents(formStudents.data);
  };
  const onAddAll = (addStudents) => {
    // setFormAll(addStudents);
  };
  const sendAll = () => {
    // api.postAll(formAll.data);
  };

  return !checkPassword ? (
    <div className={Styles.container}>
      <div className={Styles.forma}>
        <Forma checkPass={checkPass} />
      </div>
    </div>
  ) : (
    <div className={Styles.interface}>
      <div className={Styles.buttons}>
        <AddTeacher onAddTeachers={onAddTeachers} sendTeachers={sendTeachers} />
        <AddStudent onAddStudents={onAddStudents} sendStudents={sendStudents} />
        <AddAll onAddAll={onAddAll} sendAll={sendAll}/>
      </div>
    </div>
  );
};
export default OurPage;
