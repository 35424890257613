import React from "react";
import { CSVReader } from "react-papaparse";

const buttonRef = React.createRef();

export default function CSVReader1({
  dataTable,
  setChooseFile,
}) {
  const [file, setFile] = React.useState();
  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };
  React.useEffect(() => {
    setChooseFile(file);
  }, [file]);

  const handleOnFileLoad = (data) => {
    dataTable(data);
  };
  return (
    <>
      <CSVReader ref={buttonRef} onFileLoad={handleOnFileLoad} noClick noDrag>
        {({ file }) => {
          setFile(file);
          return (
            <aside>
              <button onClick={handleOpenDialog}>
                Browse file
              </button>
              <div>{file && file.name}</div>
            </aside>
          );
        }}
      </CSVReader>
    </>
  );
}
