import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import Auth from '../services/auth/Auth'
import actions from '../store/actions';
import { useDispatch } from 'react-redux';
const ENV = 'prod'
const BASE_API_URL = 'https://endpoint.triflect.nl'

const Login = (props) => {
  const [emailText, setEmailText] = useState('');
  const [passwordText, setPasswordText] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleLogin = async () => {
     const user = await Auth.loginUser(emailText, passwordText)
      .catch(function (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        enqueueSnackbar('Invalid credentials!', { variant: 'error' });
      })
    if (user && user !== 'student') {
      dispatch(actions.postUser(user))
      dispatch(actions.login(true))
    }
    if (user === 'student') {
      enqueueSnackbar("You are't a teacher!", { variant: 'error' });
    }
  };
  return (
    <Paper style={styles.section}>
      <h1>Triflect</h1>
      <div style={styles.form} >
        <input placeholder="Email" style={styles.input} type="text" onChange={ev => setEmailText(ev.target.value)} />
        <input placeholder="Password" style={styles.input} type="password" onChange={ev => setPasswordText(ev.target.value)} />
        <Button onClick={handleLogin} style={styles.button} variant="contained" type="submit" color="primary">
          Login
      </Button>
      </div>
      <center style={{ padding: '10px' }}>
        <a href={`${BASE_API_URL}/api/login_dash?env=${ENV}`}>
          <img alt="Entree logo" src="/images/entree.png" width="260" />
        </a>
      </center>
    </Paper>
  );
}

const styles = {
  section: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: 50,
    paddingLeft: 80,
    paddingRight: 80,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    marginTop: 20,
    width: '100%',
    border: '1px solid #E5E5E5',
    backgroundColor: '#F8FAFB',
    fontSize: 14,
    borderRadius: 5,
    outline: 0,
    padding: 12,
    opacity: 0.6,
    resize: 'none',
  },
  button: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 24,
    fontWeight: 500,
    marginTop: 24,
    outline: 0,
    cursor: 'pointer',
  },
};
export default Login;
