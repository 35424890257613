import React, { useRef, useEffect } from 'react';
import Styles from './NewDeletedPopup.module.css';

export default function NewDeletedPopup({ show, handleClickDone, handleClicCancel, item, message }) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClicCancel();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    show && (
      <div className={Styles.full}>
        <div className={Styles.background} ref={wrapperRef}>
          <div className={Styles.container}>
            <h1 className={Styles.title}>{message}</h1>
            <div className={Styles.buttons}>
              <button className={Styles.done} onClick={() => handleClickDone(item)}>
                Yes
              </button>
              <button className={Styles.cancel} onClick={handleClicCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
