import React, { useRef, useEffect } from "react";
import Styles from "./DataTable.module.css";

export default function EditPopup({
  show,
  changeRowData,
  setChangeRowData,
  sendNewValue,
  closePopup,
}) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopup(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      {show && (
        <div ref={wrapperRef} className={Styles.editValuePopup}>
          {Object.keys(changeRowData).map((key, i) => {
            return (
              <div key={`showChangeValue${i}`} style={{display: "flex", flexFlow: "column"}}>
                {key}
                <input
                  value={changeRowData[key]}
                  onChange={(e) => {
                    setChangeRowData({
                      ...changeRowData,
                      [key]: e.target.value,
                    });
                  }}
                />
              </div>
            );
          })}
          <div>
            <button className={Styles.done} onClick={sendNewValue}>
              OK
            </button>
            <button
              className={Styles.cancel}
              onClick={() => {
                closePopup();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
