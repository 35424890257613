let user = {
  userInfo: {},
  token: '',
  showPopup: true,
  login: false,
  classesId: [],
  classesTeacher: [],
  student: [],
  export: [],
};
const reducer = (state = user, { type, payload }) => {
  switch (type) {
    case 'POST_USER':
      return { ...state, token: payload };
    case 'POST_SHOW_POPUP':
      return { ...state, showPopup: payload };
    case 'POST_LOGIN':
      return { ...state, login: payload };
    case 'POST_USER_INFO':
      return { ...state, userInfo: payload };
    case 'POST_CLASSES_ID':
      return { ...state, classesId: payload };
    case 'POST_CLASSES_TEACHER':
      return { ...state, classesTeacher: payload };
    case 'POST_STUDENT_TEACHER':
      return { ...state, student: payload };
    case 'POST_EXPORT':
      return { ...state, export: [...state.export, payload] };
    default:
      return { ...state };
  }
};
export default reducer;
