import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isArray } from 'util';

class DropdownButton extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedYear: '',
            selectedSport: ''
        };
    }

    activeYearItem(selectedYear) {
        this.props.selectedYear(selectedYear)
    }

    activeSportItem(selectedSport) {
        this.props.selectedSport(selectedSport)
    }

    render() {
        return (
            <div>
                <button style={styles.buttonStyle} onClick={this.props.onClick}>
                <p>{isArray(this.props.children) ? this.props.children : this.props.children.length > 20 ? this.props.children.substring(0, 20) + "..." : this.props.children.substring(0, this.props.children.length)}<FontAwesomeIcon style={{ paddingLeft: 24 }} icon={this.props.active ? 'chevron-up' : 'chevron-down'} /></p>
                </button>
                <div>
                    {this.props.schoolyearSelector === true ?
                        <div>
                            {this.props.active ?
                                <div style={{
                                    position: 'absolute',
                                    border: '1px solid #eee',
                                    width: 175,
                                    zIndex: 5,
                                    backgroundColor: 'white',
                                    color: '#B6B6B6',
                                    fontSize: 14,
                                    boxShadow: `0px 0px 25px rgba(0,0,0,0.075)`,
                                    height: this.props.schoolyears.length > 4 ? 222 : 'auto',
                                    overflow: this.props.schoolyears.length > 4 ? 'scroll' : 'auto'
                                }}>
                                    <ul style={{ padding: 0, width: '100%' }}>
                                        {this.props.schoolyears.map((item, index) =>
                                            <li style={styles.listItems} onClick={() => this.activeYearItem(item)} key={index}><p style={{ paddingLeft: 12, paddingRight: 12 }}>Leerjaar {item}</p><hr style={{ width: '100%', opacity: 0.1 }} /></li>
                                        )}
                                    </ul></div> : null
                            }
                        </div> : 
                        <div>
                            {this.props.active ?
                                <div style={{
                                    position: 'absolute',
                                    border: '1px solid #eee',
                                    width: 175,
                                    zIndex: 5,
                                    backgroundColor: 'white',
                                    color: '#B6B6B6',
                                    fontSize: 14,
                                    boxShadow: `0px 0px 25px rgba(0,0,0,0.075)`,
                                    height: this.props.sports.length > 4 ? 222 : 'auto',
                                    overflow: this.props.sports.length > 4 ? 'scroll' : 'auto'
                                }}>
                                    <ul style={{ padding: 0, width: '100%' }}>
                                        {this.props.sports.map((item, index) =>  
                                            <li style={styles.listItems} onClick={() => this.activeSportItem(item)} key={index}><p style={{ paddingLeft: 12, paddingRight: 12 }}>{item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name.substring(0, item.name.length)}</p><hr style={{ width: '100%', opacity: 0.1 }} /></li>
                                        )}
                                    </ul>

                                </div> : null
                            }
                        </div>}
                </div>
            </div >
        )
    }
}

const styles = {
  buttonStyle: {
    backgroundColor: "white",
    border: "1px solid black",
    color: "black",
    fontSize: 14,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 12,
    outline: 0,
    display: "flex",
    borderRadius: 2,
    cursor: "pointer"
  },
  listItems: {
    listStyleType: "none",
  },
};

export default DropdownButton;