const postUser = (newUser) => {
    return {
        type: "POST_USER",
        payload: newUser
    }
}
const showPopup = (newData) => {
    return {
        type: "POST_SHOW_POPUP",
        payload: newData
    }
}
const login = (newData) => {
    return {
        type: "POST_LOGIN",
        payload: newData
    }
}
const userInfo = (newData) => {
    return {
        type: "POST_USER_INFO",
        payload: newData
    }
}
const postClassesId = (newData) => {
    return {
        type: "POST_CLASSES_ID",
        payload: newData
    }
}
const postClassesTeacher = (newData) => {
    return {
        type: "POST_CLASSES_TEACHER",
        payload: newData
    }
}
const postStudentTeacher = (newData) => {
    return {
        type: "POST_STUDENT_TEACHER",
        payload: newData
    }
}
const postExport = (newData) => {
    return {
        type: "POST_EXPORT",
        payload: newData
    }
}
export default {
    postUser,
    showPopup,
    login,
    userInfo,
    postClassesId,
    postClassesTeacher,
    postStudentTeacher,
    postExport,
}