import React from 'react';

const PrimaryActionButton = ({ activebutton, onClick, children }) => (
  <button
    style={activebutton === true ? styles.active : styles.inactive}
    onClick={onClick}
    disabled={!activebutton}
  >
    <p>{children}</p>
  </button>
);

const styles = {

    active: {
        backgroundColor: '#4D7CF9',
        color: 'white',
        borderRadius: 3,
        fontSize: 14,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 24,
        marginRight: 24,
        marginTop: 24,
        outline: 0,
        fontWeight: 500,
        cursor: "pointer",
        border: '1px solid black',
    },

    inactive: {
        backgroundColor: 'white',
        color: '#7E7E7E',
        border: '1px solid rgba(0,0,0,0.05)',
        borderRadius: 3,
        fontSize: 14,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 24,
        marginRight: 24,
        marginTop: 24,
        fontWeight: 500,
        outline: 0,
    }
}

export default PrimaryActionButton;