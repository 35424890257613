import React from 'react';

const BottomGradient = (display) => (
    <div>
        {display ?
            <div style={styles.gradient}></div>
            : null}
    </div>
);

const styles = {
    gradient: {
        display: 'flex',
        position: 'fixed',
        width: '100%',
        height: 125,
        background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
        bottom: 0,
        left: 0,
        right: 0
    }
}



export default BottomGradient;