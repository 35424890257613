import React, { useState, useEffect } from "react";
import Styles from "./SportPopup.module.css";
import SelectBox from "../NewDropdown/NewDropdown";

export default function RowInputs({ row, index, changeData, deleteRow, downloadPDF  }) {
  const [description, setDescription] = useState(row.description);
  const [type, setType] = useState(row.type);
  const [url, setUrl] = useState(row.url);
  useEffect(() => {
    setDescription(row.description);
    setType(row.type);
    setUrl(row.url);
  }, [row]);
  useEffect(() => {
    changeData({ description, type, url }, index);
  }, [description, type, url]);
  return (
    <div>
      <p className={Styles.link}>Link {index + 1}</p>
      <div>
        <div className={Styles.rowInputs1}>
          <input
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Beschrijving"
            value={description}
            className={Styles.firstInput}
          ></input>
          <SelectBox
            // disabled={editCheck}
            //   sportsPopUp={true}
            placeholder="Leerjaar"
            items={[{ name: "Video" }, { name: "Link" }, { name: "PDF" }]}
            selectedItem={{ name: type }}
            onSelect={(item) => setType(item.name)}
          />

          {type === "PDF" ? (
            <div className={Styles.blockPdf}>
              {url !=="" ? (
                <>
                  <div className={Styles.pdfName} onClick={()=>{downloadPDF(url)}}>{url.name || url }</div>
                   
                </>
              ) : (
                <input
                  type="file"
                  accept=".pdf"
                  onChange={(e) => {
                    setUrl({file: e.target.files[0], name:e.target.value });
                  }}
                ></input>
              )}
              {url !=="" && (
                <button
                className={Styles.buttonDeleteFile}
                onClick={() => {
                  setUrl("");
                }}
              >
                X
              </button>
              )}
              <button className={Styles.buttonDeleteInputsField} onClick={()=>deleteRow(index)}>X</button>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                // disabled={editCheck}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="URL"
                value={url}
                className={Styles.input3Th}
              ></input>
              <button
                className={Styles.buttonDeleteInputsField}
                onClick={()=>deleteRow(index)}
              >
                X
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
