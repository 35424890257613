import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassListItem from "../../components/ClassListItem";
import PrimaryActionButton from "../../components/PrimaryActionButton";
import Styles from "./EditClass.module.css";

const EditClass = ({
  user,
  search,
  setSearch,
  filtered,
  classes,
  setClasses,
  subjectId,
  setSubjectId,
  subjects,
  activeClasses,
  saveClasses,
  edit = false,
  setShowEditForm,
  setActiveClasses,
  changeClass,
}) => {
  const closeEditPopup = () => {
    setShowEditForm(false);
    setActiveClasses([]);
    classes.map((el) => {
      return (el.checked = false);
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <div>
        {user.showPopup === true ? (
          <div>
            <div className={Styles.main}>
              <div className={Styles.inputBlock}>
                <FontAwesomeIcon
                  style={{
                    marginRight: 12,
                    color: "#BFBFBF",
                  }}
                  icon={"search"}
                />
                <input
                  type="search"
                  value={search}
                  placeholder="Zoek klas"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    border: "none",
                    outline: 0,
                  }}
                />
              </div>
              <div className={Styles.classesConteiner}>
                {search.length > -1 && (
                  <ul>
                    {classes &&
                      filtered.map((item, index) => (
                        <ClassListItem
                          active={item.checked}
                          onClick={() =>
                            setClasses(
                              classes.map((it) => {
                                if (it.id === item.id)
                                  return { ...it, checked: !it.checked };
                                return it;
                              })
                            )
                          }
                          key={index}
                        >
                          {item.className}
                        </ClassListItem>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <div className={Styles.select}>
              <div>
                <h4>Subjects:</h4>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <select
                  onChange={(e) => {
                    setSubjectId(Number(e.target.value));
                  }}
                >
                  {edit &&
                    <option value={subjectId}>
                      {subjects.map((el) => {
                        if (el.id === subjectId.subjectId) {
                          return el.name;
                        }
                        return null;
                      })}
                    </option>
                  }
                  {subjects.map((item, i) => {
                    if (item.id === subjectId.subjectId) {
                      return setSubjectId(item.id);
                    }
                    return (
                      <option value={item.id} key={`subjectsasd${i}`}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className={Styles.blockWithButtons}>
              {edit ? (
                <div>
                  <PrimaryActionButton
                    activebutton={activeClasses.length < 2 ? false : true}
                    onClick={changeClass}
                  >
                    Edit class
                  </PrimaryActionButton>
                  <button
                    className={Styles.buttonEditPopupCancel}
                    onClick={closeEditPopup}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <PrimaryActionButton
                  activebutton={
                    activeClasses.length < 2 ? false : true
                  }
                  onClick={saveClasses}
                >
                  Relate classes
                </PrimaryActionButton>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div>
        {activeClasses && (
          <div style={{ marginLeft: "20px", marginTop: "31px" }}>
            {activeClasses.map((item, i) => {
              return (
                <div key={`activeClasses${i}`} className={Styles.activeClass}>
                  {classes.map((el) => {
                    if (el.id === item.id) {
                      return el.className;
                    }
                    return null;
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditClass;
