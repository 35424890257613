import React, { useState, useEffect } from "react";
import APIHelper from "../../api/APIHelper";
import { useSelector } from "react-redux";
import Styles from "./RelateClasses.module.css";
import Titlewrapper from "../../components/Titlewrapper";
import api from "../../api/APIHelper";
import EditClass from "../../components/EditClass/EditClass";
import NewDeletedPopup from "../../components/NewDeletedPopup/NewDeletedPopup"

const RelateClasses = (props) => {
  const user = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [activeClasses, setActiveClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [subjectId, setSubjectId] = useState(0);
  const [dataRelateClasses, setDataRelateClasses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [editValuesTable, setEditValuesTable] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [oldEditableClass, setOldEditableClass] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [item, setItem] = useState(false);
  const [newEditableClass, setNewEditableClass] = useState({
    classesId: [],
    subject: null,
  });
  useEffect(() => {
    getRelateClasses();
    getSubjects();
  }, []);
  useEffect(() => {
    const newArr = classes.filter((el) => {
      if (el.checked === true) {
        return el;
      }
      return null
    });
    setNewEditableClass({
      ...newEditableClass,
      classesId: newArr.map((el) => {
        return el.id;
      }),
    });
  }, [classes]);
  useEffect(() => {
    setNewEditableClass({ ...newEditableClass, subject: subjectId });
  }, [subjectId]);

  const getRelateClasses = async () => {
    const dataRelateClasses = await api.getRelateClasses();
    const newArr = dataRelateClasses.data.relateClasses.reverse();
    setDataRelateClasses(newArr);
  };

  const getSubjects = async () => {
    const subjects = await api.getSubjects();
    setSubjects(subjects);
  };

  useEffect(() => {
    if (localStorage.tokenData) {
      const token = localStorage.tokenData;
      getAllClassesForTeacher(token);
    }
  }, []);

  useEffect(() => {
    setActiveClasses([]);
    const active = [];
    classes.map((item) => {
      if (item.checked === true) {
        return active.push(item);
      }
      return null
    });
    setActiveClasses(active);
  }, [classes]);

  const getAllClassesForTeacher = async (token) => {
    let classes = await APIHelper.getAllClassesForTeacher({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (classes) {
      classes = classes
        .map((item) => ({ ...item, checked: false }))
        .sort((a, b) => {
          if (a.className > b.className) {
            return 1;
          }
          if (a.className < b.className) {
            return -1;
          }
          return 0;
        });
      setClasses(classes);
    }
  };

  const saveClasses = async () => {
    let activeId = [];
    activeClasses.map((item) => {
      return activeId.push(item.id);
    });
    const res = await api.postRelateClasses(
      activeId,
      user.userInfo.id,
      subjectId
    );
    if (res.status === 200) {
      getRelateClasses();
    } else {
      setErrorMessage("error added");
    }
    setActiveClasses([]);
    setSubjectId(0);
    classes.map((el) => {
     return el.checked = false;
    });
  };
  let filtered = classes.filter(
    (item) =>
      item.className.toLowerCase().indexOf(search.toLowerCase()) > -1 || !search
  );
  const showDeleteDataTable = async (item) => {
    setItem(item)
    setShowPopup(true)
  };
  const deleteDataTable = async (item)=>{
    setShowPopup(false)
    const res = await api.deleteRelateClasses(item.id);
    if (res === "success delete") {
      getRelateClasses();
    } else {
      setErrorMessage("error delete");
    }

  }
  const editDataTable = async (item) => {
    setOldEditableClass(item);
    classes.map((el) => {
      return el.checked = false;
    });
    setClasses(
      classes.map((it) => {
        if (item.classesId.includes(Number(it.id)))
          return { ...it, checked: !it.checked };
        return it;
      })
    );
    const newArr = [];
    classes.map((el) => {
      if (item.classesId.includes(Number(el.id))) {
       return  newArr.push(el);
      }
      return null
    });
    setActiveClasses(newArr);
    setEditValuesTable(item);
    setShowEditForm(true);
    setSubjectId(item.subjectId);
  };
  const changeClass = async () => {
    const res = await api.editRelateClasses(oldEditableClass, newEditableClass);
    if (res.status === 200) {
      getRelateClasses();
    }
    setShowEditForm(false);
    classes.map((el) => {
     return el.checked = false;
    });
    setActiveClasses([])
  };

  return (
    <div className={Styles.mainBackground}>
      <NewDeletedPopup
        show={showPopup}
        handleClickDone={deleteDataTable}
        handleClicCancel={() => setShowPopup(false)}
        item={item}
        closePopup={() => setShowPopup(false)}
        message={`Do you really want to delete this?`}
      />
      <Titlewrapper user={props.currentUser} />
      <div className={Styles.afterMain}>
        <div>
          <h1 className={Styles.title}>Relate classes</h1>
        </div>

        <div className={Styles.studentsWrapper}>
          {showEditForm ? (
            <div>
              <EditClass
                edit={showEditForm}
                setShowEditForm={setShowEditForm}
                user={user}
                search={search}
                setSearch={setSearch}
                filtered={filtered}
                classes={classes}
                setClasses={setClasses}
                subjectId={editValuesTable}
                setSubjectId={setSubjectId}
                subjects={subjects}
                activeClasses={activeClasses}
                saveClasses={saveClasses}
                setActiveClasses={setActiveClasses}
                changeClass={changeClass}
              />
            </div>
          ) : (
            <div>
              <EditClass
                user={user}
                search={search}
                setSearch={setSearch}
                filtered={filtered}
                classes={classes}
                setClasses={setClasses}
                subjectId={subjectId}
                setSubjectId={setSubjectId}
                subjects={subjects}
                activeClasses={activeClasses}
                saveClasses={saveClasses}
              />
            </div>
          )}
          <div className={Styles.line}></div>
          <div>
            <table className={Styles.table}>
              <tr style={{ border: "1px solid" }}>
                <th style={{ border: "1px solid", padding: "20px" }}>
                  Classes
                </th>
                <th style={{ border: "1px solid", padding: "20px" }}>
                  Subjects
                </th>
              </tr>
              {dataRelateClasses.map((item, i) => {
                return (
                  <tr key={`dataRelateClasses ${i}`}>
                    <td style={{ border: "1px solid", textAlign: "center" }}>
                      {classes.map((el) => {
                        if (item.classesId.includes(el.id)) {
                          return `${el.className} `;
                        }
                        return null;
                      })}
                    </td>
                    <td style={{ border: "1px solid", textAlign: "center" }}>
                      {subjects.map((el) => {
                        if (el.id !== item.id) {
                          return el.name;
                        }
                        return null;
                      })}
                    </td>
                    <td>
                      <button
                        className={Styles.buttonDelete}
                        onClick={() => showDeleteDataTable(item)}
                      >
                        delete
                      </button>
                    </td>
                    <td>
                      <button
                        className={Styles.buttonEdit}
                        onClick={() => editDataTable(item)}
                      >
                        edit
                      </button>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelateClasses;
