import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';

class LineChart extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      isShowing: false,
    };
  }
  componentDidMount() {
    this.handleGraphUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCompetence !== prevProps.selectedCompetence ||
      this.props.selectedYear !== prevProps.selectedYear
    ) {
      this.handleGraphUpdate();
    }
  }

  handleGraphUpdate = () => {
    const formattedObservations = this.props.observations.filter((o) => {
      return this.props.selectedCompetence ? this.props.selectedCompetence === o.competenceType : true;
    });
    this.setState({
      isShowing: formattedObservations.length > 0,
      data: (canvas) => {
        const ctx = canvas.getContext('2d');
        const gradient1 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient1.addColorStop(0, 'rgba(39, 66, 140, 0)');
        gradient1.addColorStop(1, 'rgba(39, 66, 140, 0.5)');

        const gradient2 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient2.addColorStop(0, 'rgba(129, 246, 195, 0)');
        gradient2.addColorStop(1, 'rgba(129, 246, 195, 0.5)');

        const gradient3 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient3.addColorStop(0, 'rgba(125, 225, 254, 0)');
        gradient3.addColorStop(1, 'rgba(125, 225, 254, 0.5)');

        // Arrays with 12 items to set the correct data on the X-asis of the graph per month
        const selfObservedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const studentObservedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const teacherObservedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        // Get average level of all the different action types within a sport, filtered by the ObservedType
        const getAverageSelectedLevel = (observation, observedBy) => {
          const observationDate = new Date(observation.date);
          const month = observationDate.getMonth();
          const testMonth = [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3];
          // eslint-disable-next-line eqeqeq
          const thisYearsAnswers = observation.rubrics.filter((a) => a.rubrickYear == this.props.selectedYear);
          let selectedLevelsSum = 0;
          thisYearsAnswers.forEach((answer) => (selectedLevelsSum += answer.levelValue || 0));

          let avgSelectedLevel = thisYearsAnswers.length ? selectedLevelsSum / thisYearsAnswers.length : 0;

          if (observedBy === 'self') {
            selfObservedByMonth[Math.abs(testMonth[month])] += avgSelectedLevel;
          }
          if (observedBy === 'student') {
            studentObservedByMonth[Math.abs(testMonth[month])] += avgSelectedLevel;
          }
          if (observedBy === 'teacher') {
            teacherObservedByMonth[Math.abs(testMonth[month])] += avgSelectedLevel;
          }
        };

        formattedObservations // check for selfreflection grading
          .filter((o) => o.userId === o.studentId)
          .forEach((o) => {
            getAverageSelectedLevel(o, 'self');
          });

        formattedObservations // check for other student grading
          .filter((o) => o.userRole === 'student' && o.userId !== o.studentId)
          .forEach((o) => {
            getAverageSelectedLevel(o, 'student');
          });

        formattedObservations // check for teacher grading
          .filter((o) => o.userRole === 'teacher')
          .forEach((o) => {
            getAverageSelectedLevel(o, 'teacher');
          });

        function calculateAverageByMonth(observedByMonth, observations) {
          return observedByMonth.map((monthSum, index) => {
            let observationsFromThisMonth = observations.filter((obs) => {
              const observationDate = new Date(obs.date);
              const month = observationDate.getMonth();
              const testMonth = [4, 5, 6, 7, 8, 9, 10, 11, 0, 1, 2, 3];
              return index === testMonth[month];
            });
            if (observationsFromThisMonth.length) {
              return +(monthSum / observationsFromThisMonth.length).toFixed(2);
            } else {
              return +monthSum;
            }
          });
        }
        const datasets = [
          {
            label: "Zelfreflectie",
            data: calculateAverageByMonth(
              selfObservedByMonth,
              formattedObservations.filter((o) => o.userId === o.studentId)
            ),
            hidden: !this.props.buttonBeweging,
            borderWidth: 1.5,
            borderColor: "rgba(39, 66, 140, 1)",
            backgroundColor: gradient1,
          },
          {
            label: "Klasgenoot",
            data: calculateAverageByMonth(
              studentObservedByMonth,
              formattedObservations.filter((o) => o.userRole === "student")
            ),
            hidden: !this.props.buttonOmgang,
            borderWidth: 1.5,
            borderColor: "rgba(129, 246, 195, 1)",
            backgroundColor: gradient2,
          },
          {
            label: "Docent",
            data: calculateAverageByMonth(
              teacherObservedByMonth,
              formattedObservations.filter((o) => o.userRole === "teacher")
            ),
            hidden: !this.props.buttonRegel,
            borderWidth: 1.5,
            borderColor: "rgba(125, 225, 254, 1)",
            backgroundColor: gradient3,
          },
        ];
        return {
          labels: ['SEP', 'OKT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAA', 'APR', 'MEI', 'JUN', 'JUL', 'AUG'],
          datasets,
        };
      },
    });
  };

  render() {
    return (
      <div style={{ height: "100%", position: "relative", zIndex: 1 }}>
        <p>{this.props.title}</p>
        {this.state.isShowing && (
          <Line
            data={this.state.data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: "bottom",
                labels: {
                  fontColor: "#B0B0B0",
                  boxWidth: 5,
                  padding: 20,
                  fontSize: 12,
                  usePointStyle: true,
                },
              },
              elements: {
                point: {
                  radius: 1,
                },
              },
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      // You can change the color, the dash effect, the main axe color, etc.
                      borderDash: [5, 15],
                      color: "#e8e8e8",
                    },
                    ticks: {
                      stepSize: 1,
                      max: 4,
                      min: 0,
                      display: false,
                    },
                  },
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false,
                    },
                  },
                ],
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default LineChart;
