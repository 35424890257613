import React, {useEffect} from 'react';
import { withRouter } from "react-router";

const TeacherLoginRedirect = ({history}) => {
  useEffect(() => {
    const token = new URLSearchParams(history.location.search).get('token');
    if (token) {
      localStorage.setItem("tokenData", token);
      localStorage.setItem("login", true);
      history.push('/');
    } else {
      history.push('/');
    }
  }, [])

  return <div></div>
}

export default withRouter(TeacherLoginRedirect);
