import React from 'react';

const Error = () => {
    return (
        <div>
            <p>Error: path does not exists</p>
        </div>
    )
}

export default Error;