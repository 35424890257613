import React, { useEffect, useState } from "react";
import SelectionButton from "../../components/SelectionButton";
import Titlewrapper from "../../components/Titlewrapper";
import api from "../../api/APIHelper";
import Styles from "./Sports.module.css";
import NewSportPopup from "../../components/SportPopup/NewSportPopup";

function Sports(props) {
  const [subjects, setSubjects] = useState([]);
  const [showSportPopup, setShowSportPopup] = useState(false);
  const [activeSport, setActiveSport] = useState("");
  const [components, setComponents] = useState([]);
  const mainFunction = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.tokenData}`,
      },
    };
    const competences = await api.getAllCompetences({ ...headers });
    const subjects = await api.getSubjects({ ...headers });
    const subjectsTeachers = await api.getSubjectTeacher({ ...headers });
    setComponents(subjectsTeachers.data);

    setSubjects(
      subjects.map((subject, index) => {
        return {
          name: subject.name,
          activeSubject: index === 0 ? true : false,

          competences: competences
            .filter((competence) => competence.subjectId === subject.id)
            .map((competence) => {
              return {
                id: competence.id,
                name: competence.name,
                activeCompetence: false,
              };
            }),
        };
      })
    );
  };

  useEffect(() => {
    mainFunction();
  }, []);

  const makeActiveCompetence = (componentId) => {
    const updatedSubjects = subjects.map((item, index) => {
      if (index === componentId) {
        return { ...item, activeSubject: !item.activeSubject };
      } else {
        return { ...item, activeSubject: false };
      }
    });
    setSubjects(updatedSubjects);
  };

  const showSportPopup2 = (selectedSport, sportId) => {
    setShowSportPopup(true);
    setActiveSport({ id: sportId, name: selectedSport });
  };

  const hidePopup = () => {
    setShowSportPopup(false);
  };

  const selectedSubject = subjects.find((comp) => comp.activeSubject === true);
  return (
    <div className={Styles.mainBackground}>
      {showSportPopup === true ? (
        <div>
          <NewSportPopup
            activeSport={activeSport}
            college={props.currentUser.college}
            show={showSportPopup}
            data={activeSport}
            onClick={hidePopup}
            components = {components}
          />
        </div>
      ) : null}

      <Titlewrapper history={props.history} user={props.currentUser} />

      <div style={{ margin: 48, borderRadius: 5 }}>
        <div>
          <h1 className={Styles.title}>Subject</h1>
          <h2 className={Styles.subTitle}>{""}</h2>
          <div className={Styles.classWrapper}>
            {subjects.map(
              (object, index) => (
                <SelectionButton
                  key={index}
                  onClick={() => makeActiveCompetence(index)}
                  active={object.activeSubject}
                >
                  {object.name}
                </SelectionButton>
              ) // remove 'bekwaamheid' to make it shorter
            )}
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <h2 className={Styles.subTitle}>Components</h2>
          <div className={Styles.studentsWrapper}>
            <div>
              {selectedSubject ? (
                <div
                  style={{
                    height: 400,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {components.map((item, index) => (
                    <li
                      onClick={() => showSportPopup2(item.name, item.id)}
                      className={Styles.listItem}
                      key={index}
                    >
                      {item.name}
                    </li>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // }
}

export default Sports;
