import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import CSVReader1 from "./ChooseFile";
import Styles from './OurPage.module.css'

// Дописать useEffect, а именно прокинуть туда вместо addTeachers все свои dataSubjects dataRubric dataClasses dataStudents
export default function FormDialog({ onAddAll, sendAll }) {
  const [open, setOpen] = React.useState(false);

  const [dataSubjects, setDataSubjects] = React.useState([]);
  const [fileSubjects, setFileSubjects] = React.useState({
    data: [],
    separator: "",
  });

  const [dataRubric, setDataRubric] = React.useState([]);
  const [fileRubric, setFileRubric] = React.useState({
    data: [],
    separator: "",
  });

  const [dataClasses, setDataClasses] = React.useState([]);
  const [fileClasses, setFileClasses] = React.useState({
    data: [],
    separator: "",
  });

  const [dataStudents, setDataStudents] = React.useState([]);
  const [fileStudents, setFileStudents] = React.useState({
    data: [],
    separator: "",
  });


  // useEffect(() => {
  //   onAddAll(addTeachers);
  // }, [addTeachers]);

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    underlineInput: {
      "& .MuiInputBase-root": {
        "& input": {
          boxShadow: "none",
          borderBottom: "none",
          paddingLeft: "15px",
          marginBottom: 0,
          "&:focus": {
            borderBottom: "none !important",
            boxShadow: "none !important",
          },
        },
      },
    },
  }));

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ifSubjects = () => {
    if (fileSubjects.data) {
      return "enable";
    } else {
      return "";
    }
  };
  const ifRubric = () => {
    if (fileRubric.data) {
      return "enable";
    } else {
      return "";
    }
  };
  const ifClasses = () => {
    if (fileClasses.data) {
      return "enable";
    } else {
      return "";
    }
  };
  const ifStudents = () => {
    if (fileStudents.data) {
      return "enable";
    } else {
      return "";
    }
  };

  const reqTech = () => {
    sendAll();
    setOpen(false);
  };

  const setDataSubjects1 = (data) => {
    let arr = [...data];
    arr.pop();
    arr.shift();
    setDataSubjects(arr);
  };

  const getFileSubjects1 = (file) => {
    const firstFile = file;
    const formData = new FormData();
    formData.append("file", firstFile);
    setFileSubjects({
      ...fileSubjects,
      data: formData,
    });
  };

  const setDataRubric1 = (data) => {
    let arr = [...data];
    arr.pop();
    arr.shift();
    setDataRubric(arr);
  };

  const getFileRubric1 = (file) => {
    const firstFile = file;
    const formData = new FormData();
    formData.append("file", firstFile);
    setFileRubric({
      ...fileRubric,
      data: formData,
    });
  };

  const setDataClasses1 = (data) => {
    let arr = [...data];
    arr.pop();
    arr.shift();
    setDataClasses(arr);
  };

  const getFileClasses1 = (file) => {
    const firstFile = file;
    const formData = new FormData();
    formData.append("file", firstFile);
    setFileClasses({
      ...fileClasses,
      data: formData,
    });
  };

  const setDataStudents1 = (data) => {
    let arr = [...data];
    arr.pop();
    arr.shift();
    setDataStudents(arr);
  };

  const getFileStudents1 = (file) => {
    const firstFile = file;
    const formData = new FormData();
    formData.append("file", firstFile);
    setFileStudents({
      ...fileStudents,
      data: formData,
    });
  };

  const separatorSubjects = (event) => {
    const separatorValue = event.target.value;
    setFileSubjects({
      ...fileSubjects,
      separator: separatorValue,
    });
  };

  const separatorRubric = (event) => {
    const separatorValue = event.target.value;
    setFileRubric({
      ...fileRubric,
      separator: separatorValue,
    });
  };

  const separatorClasses = (event) => {
    const separatorValue = event.target.value;
    setFileClasses({
      ...fileClasses,
      separator: separatorValue,
    });
  };

  const separatorStudents = (event) => {
    const separatorValue = event.target.value;
    setFileStudents({
      ...fileStudents,
      separator: separatorValue,
    });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Добавить всех
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title" style={{ marginLeft: "25px" }}>
          Добавление всех
        </DialogTitle>
        <DialogContent>
          <Container className={classes.root} style={{ minWidth: "200px" }}>
            <form
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={Styles.addCard} style={{ marginLeft: "-10px" }}>
                  <div className={Styles.addFile}>
                    <p>Add Subjects</p>
                    <CSVReader1
                      dataTable={setDataSubjects1}
                      setChooseFile={getFileSubjects1}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>Выбрать сепаратор:</p>
                      <select
                        onChange={separatorSubjects}
                        style={{ marginLeft: "10px" }}
                      >
                        <option></option>
                        <option>;</option>
                        <option>,</option>
                      </select>
                    </div>
                  </div>
                  <button disabled={ifSubjects()} style={{ marginTop: "10px" }}>
                    Show table
                  </button>
                </div>
                <div className={Styles.addCard}>
                  <div className={Styles.addFile}>
                    <p>Add Rubric</p>
                    <CSVReader1
                      dataTable={setDataRubric1}
                      setChooseFile={getFileRubric1}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>Выбрать сепаратор:</p>
                      <select
                        onChange={separatorRubric}
                        style={{ marginLeft: "10px" }}
                      >
                        <option></option>
                        <option>;</option>
                        <option>,</option>
                      </select>
                    </div>
                  </div>
                  <button disabled={ifRubric()} className={Styles.showTable}>
                    Show table
                  </button>
                </div>
                <div className={Styles.addCard}>
                  <div className={Styles.addFile}>
                    <p>Add Classes</p>
                    <CSVReader1
                      dataTable={setDataClasses1}
                      setChooseFile={getFileClasses1}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>Выбрать сепаратор:</p>
                      <select
                        onChange={separatorClasses}
                        style={{ marginLeft: "10px" }}
                      >
                        <option></option>
                        <option>;</option>
                        <option>,</option>
                      </select>
                    </div>
                  </div>
                  <button disabled={ifClasses()} className={Styles.showTable}>
                    Show table
                  </button>
                </div>
                <div className={Styles.addCard}>
                  <div className={Styles.addFile}>
                    <p>Add Students</p>
                    <CSVReader1
                      dataTable={setDataStudents1}
                      setChooseFile={getFileStudents1}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p>Выбрать сепаратор:</p>
                      <select
                        onChange={separatorStudents}
                        style={{ marginLeft: "10px" }}
                      >
                        <option></option>
                        <option>;</option>
                        <option>,</option>
                      </select>
                    </div>
                  </div>
                  <button disabled={ifStudents()} className={Styles.showTable}>
                    Show table
                  </button>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>Organization id:</p>
                <input type="number" className={Styles.addIndex} />
              </div>
            </form>
            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <button onClick={reqTech}>Отправить файл</button>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
