import React, { useState, useEffect } from "react";
import { Column, Table, SortDirection, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";
import Styles from "./DataTable.module.css";

const DataTable = ({ dataTable, headerTable, changeValue }) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState();
  const [sortedList, setSortList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState();
  const [list, setList] = useState([]);

  useEffect(() => {
    fiter(selectValue, inputValue);
  }, [inputValue]);

  useEffect(() => {
    setList(dataTable.data);
    setSortList(dataTable.data);
  }, [dataTable]);

  useEffect(() => {
    setSortDirection(SortDirection.ASC);
    setSortList(sortList({ sortBy, sortDirection }));
    setList(dataTable.data);
    setSortList(dataTable.data);
  }, []);

  const fiter = (key, value) => {
    const filtered = _.filter(list, (el) => {
      return _.includes(`${el[key]}`.toLowerCase(), value.toLowerCase());
    });
    setSortList(filtered);
  };

  const sortList = ({ sortBy, sortDirection }) => {
    let newList = _.sortBy(list, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  };

  const sort = ({ sortBy, sortDirection }) => {
    const sortedList = sortList({ sortBy, sortDirection });
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortList(sortedList);
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <input
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <select
          style={{ marginLeft: "20px" }}
          onChange={(e) => {
            setSelectValue(e.target.value);
          }}
        >
          <option></option>
          {headerTable.map(({ dataKey }, i) => {
            return <option key={`optinon${i}`}>{dataKey}</option>;
          })}
        </select>
      </div>
      <div
        style={{
          height: 400,
          minWidth: 600,
          border: "1px solid",
          overflow: "hidden",
        }}
      >
        <AutoSizer style={{ marginTop: 20 }}>
          {({ height, width }) => (
            <Table
              width={width}
              height={height}
              headerHeight={20}
              rowHeight={30}
              rowCount={sortedList.length}
              rowGetter={({ index }) => sortedList[index]}
              style={{ marginTop: 20 }}
              onRowDoubleClick={(e) => {
                changeValue(e.rowData);
              }}
              sort={sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              {headerTable.map(({ label, dataKey, width = 100 }, i) => {
                return (
                  <Column
                    key={`${i}headerTable`}
                    width={width}
                    label={label}
                    dataKey={dataKey}
                    className={Styles.item}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default DataTable;
