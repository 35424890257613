import React from 'react';

const ClassListItem = ({ active, onClick, children }) => (
    <div style={{ display: 'flex' }}>
        <input type="checkbox" style={{ marginLeft: 12 }} checked={active ? true : false} onChange={onClick}></input>
        <li onClick={onClick} style={active ? styles.active : styles.inactive}>{children}</li>
    </div>
);

const styles = {
    active: {
        listStyleType: 'none',
        color: '#576BFF',
        fontSize: 14,
        paddingRight: 26,
        paddingBottom: 26,
        paddingLeft: 12,
        fontWeight: 400,
        cursor: 'pointer'
    },
    inactive: {
        listStyleType: 'none',
        color: 'black',
        fontSize: 14,
        paddingRight: 26,
        paddingBottom: 26,
        paddingLeft: 12,
        fontWeight: 300,
        cursor: 'pointer'
    }
}

export default ClassListItem;