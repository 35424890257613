import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./NewDropdown.module.css";

class SelectBox extends React.Component {
  state = {
    showItems: false,
  };

  dropDown = () => {
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };

  selectItem = (item) => {
    this.setState({
      showItems: false,
    });
    this.props.onSelect(item);
  };

  render() {
    return (
      <div className={Styles.mainContainer}>
        <button
          disabled={this.props.disabled}
          onClick={this.dropDown}
          className={`${
            this.props.sportsPopUp === true
              ? Styles.buttonBg1
              : this.props.selectedItem
              ? Styles.buttonBg2
              : Styles.button
          }`}
        >
          <p
            className={`${
              this.props.sportsPopUp === true
                ? Styles.p1
                : this.props.selectedItem
                ? Styles.p
                : Styles.p2
            }`}
          >
            {this.props.selectedItem
              ? this.props.selectedItem.name
              : this.props.placeholder}
            <FontAwesomeIcon
              className={Styles.fontAwesomeIcon}
              icon={this.state.showItems ? "chevron-up" : "chevron-down"}
            />
          </p>
        </button>

        {this.state.showItems ? (
          <div
            className={`
            ${Styles.showItems} 
            ${
              this.props.items.length > 4 ? Styles.showItems : Styles.showItems2
            }
            `}
          >
            <ul className={Styles.ul}>
              {this.props.onCreate && (
                <li
                  className={Styles.liVoeg}
                  onClick={() => {
                    this.props.onCreate();
                    this.setState({ showItems: false });
                  }}
                >
                  + Voeg toe
                  <hr className={Styles.line} />
                </li>
              )}
              {this.props.items.length > 0
                ? this.props.items.map((item, index) => (
                    <li
                      className={Styles.liItems}
                      onClick={() => this.selectItem(item)}
                      key={item.id}
                    >
                      <p className={Styles.liVoeg}>
                        {item.name.length >
                        (this.props.longDescription === true ? 100 : 20)
                          ? item.name.substring(
                              0,
                              this.props.longDescription === true ? 100 : 20
                            ) + "..."
                          : item.name.substring(0, item.name.length)}
                      </p>
                      <hr className={Styles.line} />
                    </li>
                  ))
                : "There is no items... Try to select anything different, or add yours ;)"}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SelectBox;
