import React from 'react';

class Inputfield extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  setColor(param) {
    switch (param) {
      case 1:
        return '#0ad80a';
      case 2:
        return '#4979F9';
      case 3:
        return '#F83645';
      case 4:
        return 'black';
      default:
      // do nothing
    }
  }

  render() {
    const color = this.setColor(this.props.level);
    return (
      <div>
        <div style={{ opacity: this.props.active ? 0.3 : 1, marginTop: 24, marginRight: 24 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                backgroundColor: 'white',
                width: 7.5,
                height: 7.5,
                marginRight: 12,
                borderRadius: '50%',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: color,
              }}
            />
            <div style={{ color: color, fontSize: 14, fontWeight: 400 }}>Niveau {this.props.level}</div>
          </div>

          <textarea
            rows="2"
            value={this.props.value}
            style={styles.fieldstyling}
            onChange={ev => this.props.onChange(ev.target.value)}
            placeholder="Niveaubeschrijving"
            onFocus={this.props.onFocus}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  fieldstyling: {
    marginTop: 12,
    width: '100%',
    border: '1px solid #E5E5E5',
    backgroundColor: '#F8FAFB',
    fontSize: 14,
    borderRadius: 5,
    outline: 0,
    padding: 12,
    opacity: 0.6,
    resize: 'none',
  },
  colorIndicator: {
    width: 7.5,
    height: 7.5,
    marginRight: 12,
    borderRadius: '50%',
    backgroundColor: 'white',
    border: '3px solid #81F6C3',
  },
};

export default Inputfield;
