import api from '../../api/APIHelper'
import moment from 'moment';
import decode from 'jwt-decode'
const loginUser = async (email, password) => {
    const tokenUser = await api.Login(email, password)
    if (tokenUser) {
        const user = decode(tokenUser)
        if (user.role === 'teacher') {
            localStorage.setItem("tokenData", tokenUser);
            localStorage.setItem("login", true);
            document.cookie = `logged_in=true; expires=Tue, ${moment().add(1, 'year').format('DD MMM YYYY hh:mm:ss Z')}; path=/`;
        } else {
            return user.role
        }
    }
    return tokenUser
}
export default {
    loginUser
}

