import React, { useState, createContext } from "react";

const initialState = [];

const ClassesContext = createContext(initialState);

const ClassesProvider = ({ children }) => {
  const [classSelection, setClassSelection] = useState([]);
  const [activeClassCheck, setActiveClassCheck] = useState([]);
  const [checkExport, setCheckExport] = useState(true);

  return (
    <ClassesContext.Provider
      value={{
        classSelection,
        setClassSelection,
        activeClassCheck,
        setActiveClassCheck,
        checkExport,
        setCheckExport,
      }}
    >
      {children}
    </ClassesContext.Provider>
  );
};

export { ClassesContext, ClassesProvider };
