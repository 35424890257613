import React, { useState } from 'react';
import StudentListItem from '../StudentListItem/StudentListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const GetUsers = (props) => {
  const { classesTeacher } = useSelector((state) => state)
  const [search, setSearch] = useState('')
  
  let student = [];
  if (classesTeacher) {
    classesTeacher.map(item => {
      if (item.id === props.activeClassCheck.id) {
        return student = item
      }
      return null
    })
  }
  let filtered = student.length === undefined ? student.students.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || !search) : false;
  return (
    <div style={{ height: '100%' }}>
      <div style={{ border: '1px solid #EEEEEE', display: 'flex', width: 400, borderRadius: 5 }}>
        <FontAwesomeIcon
          style={{ paddingLeft: 12, paddingTop: 12, paddingRight: 0, paddingBottom: 12, color: '#BFBFBF' }}
          icon={'search'}
        />
        <input
          type="search"
          value={search}
          placeholder="Zoek leerling"
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: '100%', border: 'none', padding: 12, fontSize: 12, outline: 0, opacity: 0.6, WebkitAppearance: 'textfield' }}
        />
      </div>
      <div style={{ display: 'flex', height: 364, width: '100%' }}>
        {search.length > -1 && (
          <ul
            style={{
              padding: 0,
              display: 'flex',
              overflow: 'scroll',
              flexDirection: 'column',
              width: '100%',
              border: '1px solid #EEEEEE',
            }}
          >
            {student.length === undefined ? filtered.map((item, index) => (
              <StudentListItem student={item} key={`user_${index}`}>
                {item.name}
              </StudentListItem>
            )) : false}
          </ul>
        )}
      </div>
    </div>
  );
}
// }

export default GetUsers;
