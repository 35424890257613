import React, { useState, useEffect } from 'react';
import Titlewrapper from '../../components/Titlewrapper';
import SelectBox from '../../components/NewDropdown/NewDropdown';
import Inputfield from '../../components/Inputfield';
import BottomGradient from '../../components/BottomGradient';
import { isEmpty } from 'lodash';
import api from '../../api/APIHelper';
import decode from 'jwt-decode';
import Styles from './Rubrics2.module.css';

function Rubriks(props) {
  const [allCompetences, setAllCompetences] = useState([]);
  const [allSports, setAllSports] = useState([]);
  const [allRubriks, setAllRubriks] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [rubricCategories, setRubricCategories] = useState([]);
  const [selectedRubricCategories, setSelectedRubricCategories] = useState(null);
  const [selectedCompetence, setSelectedCompetence] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedRubrik, setSelectedRubrik] = useState(null);
  // const [levelsRubrik, setLevelsRubrik] = useState(null);
  const [isAddingRubricCategory, setIsAddingRubricCategory] = useState(false);
  const [isAddingCompetence, setIsAddingCompetence] = useState(false);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [isAddingSport, setIsAddingSport] = useState(false);
  const [isAddingType, setIsAddingType] = useState(false);
  const [isAddCheck, setIsAddCheck] = useState(false);
  const [checkSave, setCheckSave] = useState(false);
  const [newSport, setNewSport] = useState('');
  const [newCompetence, setNewCompetence] = useState('');
  const [newQuestion, setNewQuestion] = useState('');
  const [newRubricCategory, setNewRubricCategory] = useState('');
  const [areDescriptionsUpdated, setAreDescriptionsUpdated] = useState(false);
  const [newType, setNewType] = useState('');
  const [newYear, setNewYear] = useState('');
  const headers = { headers: { Authorization: `Bearer ${localStorage.tokenData}` } };
  // this.COLLEGE_NAME = props.currentUser.college
  //   this.state = {
  //     allCompetences: [],
  //     allSports: [],
  //     allRubriks: [],
  //     allTypes: [],
  //     rubricCategories: [],
  //     selectedRubricCategories: null,
  //     selectedCompetence: null,
  //     selectedYear: null,
  //     selectedSport: null,
  //     selectedType: null,
  //     selectedQuestion: null,
  //     selectedRubrik: null,
  //     levelsRubrik: null,
  //     forServerLevels: null,
  //     isAddingRubricCategory: false,
  //     isAddingCompetence: false,
  //     isAddingQuestion: false,
  //     isAddingSport: false,
  //     isAddingType: false,
  //     isAddCheck: false,
  //     checkSave: false,
  //     newSport: '',
  //     newCompetence: '',
  //     newQuestion: '',
  //     newRubricCategory: '',
  //     newType: '',
  //     newYear: '',
  //     headers: {
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.tokenData}`
  //       }
  //     }
  //   };
  // }

  const getSports = async () => {
    const sports = await api.getSubjects(headers);
    setAllSports(sports);
  };

  const getRubricCategories = async () => {
    const rubricCategories = await api.getRubricsCategories(headers);
    setRubricCategories(rubricCategories);
  };

  useEffect(() => {
    getSports();
    getRubricCategories();
  }, []);

  const handleSaveRubric = async () => {
    const newRubric = {
      subject: newSport ? newSport : selectedSport.id,
      competenceId: newCompetence ? newCompetence : selectedCompetence.id,
      name: newQuestion,
      componentId: newType ? newType : selectedType.id,
      year: newYear.length ? newYear : selectedYear.name,
      rubricCategory: newRubricCategory ? newRubricCategory : selectedRubricCategories.id,
      userId: decode(localStorage.tokenData).userId,
      levelsRubrik: {
        title: '',
        description: '',
      },
    };
    const validated = ['subject', 'competenceId', 'name', 'componentId', 'year', 'rubricCategory'].reduce(
      (isValid, key) => {
        if (!newRubric[key]) return false;
        if (typeof newRubric[key] === 'number') return isValid;
        return isValid && !isEmpty(newRubric[key]);
      },
      true
    );

    if (validated) {
      const rubric = await api.postRubric(newRubric, headers);

      if (rubric) {
        let allRubriks = await api.getRubrics(rubric.competenceId, rubric.componentId, rubric.year, headers);
        allRubriks = allRubriks.map((item) => ({
          ...item,
          name: `${item.name}-${item.rubricCategoryName}`,
        }));
        setAllRubriks(allRubriks);
        setSelectedRubrik(null);
        handleCancel();
      }
    }
  };

  const handleCancel = () => {
    setIsAddingRubricCategory(false);
    setIsAddingCompetence(false);
    setIsAddingQuestion(false);
    setIsAddingSport(false);
    setIsAddingType(false);
    setIsAddCheck(false);
    setNewSport('');
    setNewCompetence('');
    setNewQuestion('');
    setNewRubricCategory('');
    setNewType('');
    setNewYear('');
  };

  const handleSportSelection = (selected) => {
    setSelectedSport(selected);
    setSelectedType(null);
    setSelectedQuestion(null);
    setSelectedYear(null);
    setSelectedRubrik(null);
    setSelectedRubricCategories(null);
    setAreDescriptionsUpdated(false);
  };

  const handleYearSelection = async (selected) => {
    const competences = await api.getCompetencesFromUser(selectedSport.id, selected.id, headers);
    const components = await api.getComponents(selectedSport.id, selected.id, headers);
    setAllCompetences(competences);
    setAllTypes(components);
    setSelectedCompetence(null);
    setSelectedYear(selected);
    setSelectedType(null);
    setSelectedQuestion(null);
    setSelectedRubrik(null);
    setAreDescriptionsUpdated(false);
  };

  const handleCompetenceSelection = async (selected) => {
    let allRubriks;
    if (selectedType) {
      allRubriks = await api.getRubrics(selected.id, selectedType.id, selectedYear.id, headers);
      allRubriks = allRubriks.map((item) => ({
        ...item,
        name: `${item.name}-${item.rubricCategoryName}`,
      }));
    }
    setAllRubriks(allRubriks || []);
    setSelectedCompetence(selected);
    setSelectedQuestion(null);
    setSelectedRubrik(null);
    setSelectedRubricCategories(null);
  };

  const handleTypeSelection = async (selected) => {
    let allRubriks;
    if (selectedCompetence) {
      allRubriks = await api.getRubrics(selectedCompetence.id, selected.id, selectedYear.id, headers);
      allRubriks = allRubriks.map((item) => ({
        ...item,
        name: `${item.name}-${item.rubricCategoryName}`,
      }));
    }
    setAllRubriks(allRubriks || []);
    setSelectedType(selected);
    setSelectedQuestion(null);
    setSelectedRubrik(null);
    // setLevelsRubrik(null);
    setAreDescriptionsUpdated(false);
    setSelectedRubricCategories(null);
  };

  const handleDescriptionUpdate = (value, level, title) => {
    const newLevelsRubrik = selectedRubrik.map((item, index) => {
      if (level === index + 1) {
        item.description = value;
        item.title = title;
        item.levelValue = level;
        return item;
      } else {
        return item;
      }
    });
    setAreDescriptionsUpdated(true);
    setCheckSave(true);
    setSelectedRubrik(newLevelsRubrik);
    return null;
  };

  const handleSaveLevelDescriptions = async () => {
    const levels = await api.postLevelsRubric(selectedRubrik, headers, selectedQuestion.id);
    let allRubriks = await api.getRubrics(selectedCompetence.id, selectedType.id, selectedYear.id, headers);
    allRubriks = allRubriks.map((item) => ({
      ...item,
      name: `${item.name}-${item.rubricCategoryName}`,
    }));
    setAllRubriks(allRubriks);
    setSelectedRubrik(levels);
    setCheckSave(false);
  };

  const handleRubricCategoriesSelection = (value) => {
    setSelectedRubricCategories(value);
  };

  useEffect(() => {
    if (isAddCheck === false) {
      if (
        !!(newSport || selectedSport) &&
        !!(newCompetence || selectedCompetence) &&
        !!newQuestion &&
        !!(newType || selectedType) &&
        !!(newYear || selectedYear) &&
        !!(newRubricCategory || selectedRubricCategories)
      ) {
        setIsAddCheck(true);
      }
    }
    if (selectedRubrik) {
      if (selectedRubrik.length < 4) {
        setSelectedRubrik([
          ...selectedRubrik,
          {
            title: '',
            description: '',
          },
        ]);
      }
    }

    if (selectedRubrik && selectedQuestion && selectedRubrik.id === selectedQuestion.id) {
      return;
    }
    let prevStateSselectedQuestion;
    setSelectedQuestion((prevState) => {
      prevStateSselectedQuestion = prevState;
      return selectedQuestion;
    });
    if (prevStateSselectedQuestion !== selectedQuestion) {
      const selectedRubrik = selectedQuestion ? allRubriks.find((rubrik) => selectedQuestion.id === rubrik.id) : null;
      setSelectedRubrik(selectedRubrik ? selectedRubrik.levels : null);
    }
  });

  const yearsMapper = [1, 2, 3, 4, 5];
  const years = yearsMapper.map((i) => ({ name: `${i}`, id: `${i}` }));
  return (
    <div className={Styles.mainBackground}>
      <Titlewrapper user={props.currentUser} />

      <div className={Styles.afterMain}>
        <div>
          <h1 className={Styles.title}>Rubrics</h1>
          <h2 className={Styles.subTitle}>Haal de gewenste rubrics op of maak een nieuwe aan</h2>
        </div>

        <div className={Styles.studentsWrapper}>
          <div>
            <div>
              <div className={Styles.inputContainer}>
                {isAddingSport ? (
                  <input
                    type="text"
                    value={newSport}
                    placeholder="Enter a subject"
                    onChange={(ev) => setNewSport(ev.target.value)}
                    className={Styles.input}
                  />
                ) : (
                  <SelectBox
                    onCreate={() => {
                      setIsAddingSport(true);
                    }}
                    placeholder="Vak"
                    items={allSports}
                    selectedItem={selectedSport}
                    onSelect={handleSportSelection}
                  />
                )}
                {isAddingSport ? (
                  <input
                    type="number"
                    value={newYear}
                    min={1}
                    max={5}
                    placeholder="Enter a year"
                    onChange={(ev) => setNewYear(ev.target.value)}
                    className={Styles.input}
                  />
                ) : (
                  selectedSport && (
                    <SelectBox
                      placeholder="Leerjaar"
                      items={years}
                      selectedItem={selectedYear}
                      onSelect={handleYearSelection}
                    />
                  )
                )}
                {isAddingCompetence || isAddingSport ? (
                  <input
                    value={newCompetence}
                    type="text"
                    placeholder="Enter a competence"
                    onChange={(ev) => setNewCompetence(ev.target.value)}
                    className={Styles.input}
                  />
                ) : (
                  selectedYear && (
                    <SelectBox
                      placeholder="Competentie"
                      items={allCompetences}
                      selectedItem={selectedCompetence}
                      onSelect={handleCompetenceSelection}
                      onCreate={() => {
                        setIsAddingCompetence(true);
                      }}
                    />
                  )
                )}
                {isAddingCompetence || isAddingType || isAddingSport ? (
                  <input
                    type="text"
                    value={newType}
                    placeholder="Enter a type"
                    onChange={(ev) => setNewType(ev.target.value)}
                    className={Styles.input}
                  />
                ) : (
                  selectedYear && (
                    <SelectBox
                      onCreate={() => {
                        setIsAddingType(true);
                      }}
                      placeholder="Onderdeel" //types or components
                      name="venue[country_id]"
                      items={allTypes}
                      selectedItem={selectedType}
                      onSelect={handleTypeSelection}
                    />
                  )
                )}

                {isAddingCompetence || isAddingType || isAddingSport || isAddingRubricCategory ? (
                  <input
                    type="text"
                    value={newRubricCategory}
                    placeholder="Enter a rubric category"
                    onChange={(ev) => setNewRubricCategory(ev.target.value)}
                    className={Styles.input}
                  />
                ) : null
                //     (
                //   selectedType && (
                //     <SelectBox
                //       onCreate={() => {
                //         setIsAddingRubricCategory(true);
                //       }}
                //       placeholder="Categorie"
                //       name="venue[country_id]"
                //       items={rubricCategories}
                //       selectedItem={selectedRubricCategories}
                //       onSelect={handleRubricCategoriesSelection}
                //     />
                //   )
                // )
                }
              </div>

              {isAddingCompetence || isAddingType || isAddingSport || isAddingQuestion || isAddingRubricCategory ? (
                <div className={Styles.inputContainer}>
                  <input
                    type="text"
                    value={newQuestion}
                    placeholder="Enter a question"
                    onChange={(ev) => setNewQuestion(ev.target.value)}
                    className={Styles.inputQuestion}
                  />
                  <div className={Styles.controls}>
                    <button
                      disabled={!isAddCheck}
                      className={isAddCheck ? Styles.ActiveSaveButton : Styles.saveButton}
                      onClick={handleSaveRubric}
                    >
                      +
                    </button>
                    <button className={Styles.cancelButton} onClick={handleCancel}>
                      Annuleer
                    </button>
                  </div>
                </div>
              ) : (
                selectedRubricCategories && (
                  <div className={Styles.selectBox}>
                    <SelectBox
                      onCreate={() => {
                        setIsAddingQuestion(true);
                      }}
                      placeholder="Vraag"
                      items={allRubriks}
                      getQuestion={setSelectedQuestion}
                      selectedItem={selectedQuestion}
                      onSelect={(selected) => setSelectedQuestion(selected)}
                    />
                  </div>
                )
              )}

              {selectedRubrik && !(isAddingCompetence || isAddingType || isAddingSport || isAddingQuestion) ? (
                <>
                  <div>
                    {
                      (selectedRubrik.sort((prev, next) => prev.levelValue - next.levelValue),
                      selectedRubrik.map((item, index) => {
                        let i = [1, 2, 3, 4];
                        const title = ['Beginnend', 'Ontwikkelend', 'Behaald', 'Laatste'];
                        return (
                          <Inputfield
                            key={index}
                            level={index + 1}
                            value={item.description}
                            onChange={(value) => handleDescriptionUpdate(value, i[index], title[index])}
                          />
                        );
                      }))
                    }
                  </div>

                  {areDescriptionsUpdated && (
                    <div className={Styles.actionsContainer}>
                      <button
                        disabled={!checkSave}
                        className={checkSave ? Styles.ActiveSaveButton : Styles.saveButton}
                        onClick={handleSaveLevelDescriptions}
                      >
                        Opslaan
                      </button>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <BottomGradient />
    </div>
  );
}

export default Rubriks;
