import React, { useState } from 'react';
import Forma from '../OurPage/Forma';
import api from '../../api/APIHelper';
import DataTable from './DataTable';
import Styles from './DataBaseReq.module.css';
import EditPopup from './EditPopup';
import ConfirmPopup from './ConfirmPopup';

const DataBase = () => {
  const [checkPassword, setCheckPassword] = useState(true);
  const [requestData, setRequestData] = useState({ data: [], title: '' });
  const [activeHeader, setActiveHeader] = useState([]);
  const [showChangeValue, setShowChangeValue] = useState(false);
  const [changeRowData, setChangeRowData] = useState({});
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [prevState, setPrevState] = useState({});

  const nameSchools = 'Schools';
  const nameObservations = 'Observations';
  const nameStudents = 'Students';
  const nameSubjects = 'Subjects';
  const nameUsers = 'Users';
  const nameRubrics = 'Rubrics';
  const nameTeachers = 'Teachers';
  const nameCompetences = 'Competences';
  const nameComponents = 'Components';
  const nameLevels = 'Levels';
  const nameAnsweredRubric = 'AnsweredRubric';
  const nameSchoolClass = 'SchoolClass';

  const checkPass = (password) => {
    const secretNumber = 1;
    if (Number(password) === secretNumber) {
      setCheckPassword(false);
    } else {
      alert('Неверный пароль');
    }
  };

  const headerTableSchool = [
    { label: 'Name', width: 300, dataKey: 'name' },
    { label: 'Id', dataKey: 'id' },
    { label: 'Created at', width: 300, dataKey: 'createdAt' },
    { label: 'Updated at', width: 300, dataKey: 'updatedAt' },
    { label: 'Organisation id', width: 300, dataKey: 'organisationId' },
  ];
  const headerTableObservations = [
    { label: 'Id', dataKey: 'id' },
    { label: 'User id', width: 200, dataKey: 'userId' },
    { label: 'Student id', width: 200, dataKey: 'studentId' },
    { label: 'Student class', width: 400, dataKey: 'studentClass' },
    { label: 'Student year', width: 400, dataKey: 'studentYear' },
    { label: 'Subject id', dataKey: 'subjectId' },
    { label: 'Created at', width: 400, dataKey: 'createdAt' },
    { label: 'Updated at', width: 400, dataKey: 'updatedAt' },
    { label: 'Date', width: 400, dataKey: 'date' },
  ];
  const headerTableStudents = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 350, dataKey: 'name' },
    { label: 'Stam', width: 200, dataKey: 'stam' },
    { label: 'Year', dataKey: 'year' },
    { label: 'Sickness', width: 200, dataKey: 'sickness' },
    { label: 'Injuries', width: 300, dataKey: 'injuries' },
    { label: 'Created at', width: 200, dataKey: 'createdAt' },
    { label: 'Updated at', width: 200, dataKey: 'updatedAt' },
  ];
  const headerTableSubjects = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 200, dataKey: 'name' },
    { label: 'School id', width: 200, dataKey: 'schoolId' },
    { label: 'Created at', width: 200, dataKey: 'createdAt' },
    { label: 'Updated at', width: 200, dataKey: 'updatedAt' },
  ];
  const headerTableUsers = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Email', width: 200, dataKey: 'email' },
    { label: 'Login', width: 200, dataKey: 'login' },
    { label: 'Password', width: 200, dataKey: 'password' },
    { label: 'Role', width: 200, dataKey: 'role' },
    { label: 'Student id', width: 200, dataKey: 'studentId' },
    { label: 'Teacher id', width: 200, dataKey: 'teacherId' },
    { label: 'Created at', width: 200, dataKey: 'createdAt' },
    { label: 'Updated at', width: 200, dataKey: 'updatedAt' },
  ];
  const headerTableRubrics = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 400, dataKey: 'name' },
    { label: 'Rubric category id', width: 200, dataKey: 'rubricCategoryId' },
    { label: 'Statement', width: 200, dataKey: 'statement' },
    { label: 'Year', width: 200, dataKey: 'year' },
    { label: 'Competence id', width: 200, dataKey: 'competenceId' },
    { label: 'Component id', width: 200, dataKey: 'componentId' },
    { label: 'Created at', width: 200, dataKey: 'createdAt' },
    { label: 'Updated at', width: 200, dataKey: 'updatedAt' },
  ];
  const headerTableTeachers = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 400, dataKey: 'name' },
    { label: 'Organisation id', width: 200, dataKey: 'organisationId' },
    { label: 'Subjects list', width: 200, dataKey: 'subjectsList' },
    { label: 'Created at', width: 200, dataKey: 'createdAt' },
    { label: 'Updated at', width: 200, dataKey: 'updatedAt' },
  ];
  const headerTableCompetences = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 400, dataKey: 'name' },
    { label: 'Links', width: 200, dataKey: 'links' },
    { label: 'Subjects id', width: 200, dataKey: 'subjectId' },
  ];
  const headerTableComponents = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Name', width: 400, dataKey: 'name' },
    { label: 'Links', width: 200, dataKey: 'links' },
    { label: 'Subjects id', width: 200, dataKey: 'subjectId' },
  ];
  const headerTableLevels = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Created at', dataKey: 'createdAt' },
    { label: 'Updated at', dataKey: 'updatedAt' },
    { label: 'Description', width: 200, dataKey: 'description' },
    { label: 'Level value', width: 200, dataKey: 'levelValue' },
    { label: 'Rubric id', width: 200, dataKey: 'rubricId' },
    { label: 'Title', width: 200, dataKey: 'title' },
  ];
  const headerTableAnsweredRubric = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Selected level id', dataKey: 'selectedLevelId' },
    { label: 'Observation id', dataKey: 'observationId' },
    { label: 'Rubric id', dataKey: 'rubricId' },
    { label: 'Remarks', dataKey: 'remarks' },
    { label: 'Updated at', dataKey: 'updatedAt' },
    { label: 'Created at', dataKey: 'createdAt' },
  ];
  const headerTableSchoolClass = [
    { label: 'Id', dataKey: 'id' },
    { label: 'Class name', dataKey: 'className' },
    { label: 'Year', dataKey: 'year' },
    { label: 'Education level id', dataKey: 'educationLevelId' },
    { label: 'School id', dataKey: 'schoolId' },
    { label: 'Updated at', dataKey: 'updatedAt' },
    { label: 'Created at', dataKey: 'createdAt' },
  ];

  const getDataSchools = async () => {
    const schools = await api.getSchools();
    setActiveHeader(headerTableSchool);
    setRequestData({ data: schools.data.schools, title: nameSchools });
  };
  const getDataObservations = async () => {
    const observations = await api.getObservations();
    setActiveHeader(headerTableObservations);
    setRequestData({
      data: observations.data.observations,
      title: nameObservations,
    });
  };
  const getDataStudents = async () => {
    const students = await api.getStudents();
    setActiveHeader(headerTableStudents);
    setRequestData({ data: students.data.students, title: nameStudents });
  };
  const getDataSubjects = async () => {
    const subjects = await api.getSubjectss();
    setActiveHeader(headerTableSubjects);
    setRequestData({ data: subjects.data.subjects, title: nameSubjects });
  };
  const getDataUsers = async () => {
    const users = await api.getUsers();
    setActiveHeader(headerTableUsers);
    setRequestData({ data: users.data.users, title: nameUsers });
  };
  const getDataRubrics = async () => {
    const rubrics = await api.getRubricss();
    setActiveHeader(headerTableRubrics);
    setRequestData({ data: rubrics.data.rubrics, title: nameRubrics });
  };
  const getDataTeachers = async () => {
    const teachers = await api.getTeachers();
    setActiveHeader(headerTableTeachers);
    setRequestData({ data: teachers.data.teachers, title: nameTeachers });
  };
  const getDataCompetences = async () => {
    const competences = await api.getCompetences();
    setActiveHeader(headerTableCompetences);
    setRequestData({
      data: competences.data,
      title: nameCompetences,
    });
  };
  const getDataComponents = async () => {
    const components = await api.getAllComponents();
    setActiveHeader(headerTableComponents);
    setRequestData({ data: components.data.components, title: nameComponents });
  };
  const getDataLevels = async () => {
    const levels = await api.getLevels();
    setActiveHeader(headerTableLevels);
    setRequestData({ data: levels.data.levels, title: nameLevels });
  };
  const getDataAnsweredRubric = async () => {
    const answeredRubric = await api.getAnsweredRubric();
    setActiveHeader(headerTableAnsweredRubric);
    setRequestData({
      data: answeredRubric.data.answeredRubric,
      title: nameAnsweredRubric,
    });
  };
  const getDataSchoolClass = async () => {
    const schoolClass = await api.getSchoolClass();
    setActiveHeader(headerTableSchoolClass);
    setRequestData({
      data: schoolClass.data.schoolClass,
      title: nameSchoolClass,
    });
  };
  // function useOutsideAlerter(ref) {
  //   useEffect(() => {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setShowChangeValue(false);
  //       }
  //     }
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [ref]);
  // }
  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);

  const changeValue = (rowData) => {
    setShowChangeValue(true);
    setChangeRowData(rowData);
    setPrevState(rowData);
  };
  const sendNewValue = () => {
    setShowConfirmPopUp(true);
  };
  const closePopup = () => {
    setShowChangeValue(false);
  };
  const confirmOk = async () => {
    setShowConfirmPopUp(false);
    try {
      const res = await api.testEditValues(prevState, changeRowData, requestData.title);
      switch (res.data.tableName) {
        case nameSchools:
          getDataSchools();
          break;
        case nameObservations:
          getDataObservations();
          break;
        case nameStudents:
          getDataStudents();
          break;
        case nameSubjects:
          getDataSubjects();
          break;
        case nameUsers:
          getDataUsers();
          break;
        case nameRubrics:
          getDataRubrics();
          break;
        case nameTeachers:
          getDataTeachers();
          break;
        case nameCompetences:
          getDataCompetences();
          break;
        case nameComponents:
          getDataComponents();
          break;
        case nameLevels:
          getDataLevels();
          break;

        default:
          break;
      }
    } catch (error) {
      alert('dasasd');
    }
  };
  const confirmCancel = async () => {
    setShowConfirmPopUp(false);
  };

  return !checkPassword ? (
    <div>
      <div>
        <Forma checkPass={checkPass} />
      </div>
    </div>
  ) : (
    <div>
      <ConfirmPopup confirmCancel={confirmCancel} confirmOk={confirmOk} show={showConfirmPopUp} />

      <EditPopup
        show={showChangeValue}
        closePopup={closePopup}
        changeRowData={changeRowData}
        setChangeRowData={setChangeRowData}
        sendNewValue={sendNewValue}
      />
      <div style={{ marginTop: '25px', marginLeft: '30px' }}>
        <button
          onClick={getDataSchools}
          className={`${Styles.button} ${requestData.title === nameSchools && Styles.active}`}
        >
          {nameSchools}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameObservations && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataObservations}
        >
          {nameObservations}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameStudents && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataStudents}
        >
          {nameStudents}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameSubjects && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataSubjects}
        >
          {nameSubjects}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameUsers && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataUsers}
        >
          {nameUsers}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameRubrics && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataRubrics}
        >
          {nameRubrics}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameTeachers && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataTeachers}
        >
          {nameTeachers}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameCompetences && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataCompetences}
        >
          {nameCompetences}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameComponents && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataComponents}
        >
          {nameComponents}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameLevels && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataLevels}
        >
          {nameLevels}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameAnsweredRubric && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataAnsweredRubric}
        >
          {nameAnsweredRubric}
        </button>
        <button
          className={`${Styles.button} ${requestData.title === nameSchoolClass && Styles.active}`}
          style={{ marginLeft: '10px' }}
          onClick={getDataSchoolClass}
        >
          {nameSchoolClass}
        </button>
      </div>
      <div
        style={{
          marginLeft: '30px',
          marginTop: '25px',
        }}
      >
        {requestData.data.length !== 0 && (
          <div>
            <DataTable dataTable={requestData} headerTable={activeHeader} changeValue={changeValue} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataBase;
