import React from 'react';
import Moment from 'react-moment';
import Styles from './ColumnItem.module.css'


const ColumnItem = ({
  title,
  date,
  sport,
  competence,
  from,
  to,
  classes,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  deleted,
  object,
  handeleClickDeletedItem,
}) => {
  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={style}
      className={Styles.containerColumn}
    >
      <ul className={Styles.dataColumn}>
        {title ? (
          <li className={Styles.dataColumnTitle}>{date}</li>
        ) : (
          <Moment format="DD/MM/YYYY" className={Styles.columnItem}>
            {date}
          </Moment>
        )}
      </ul>

      <ul className={Styles.dataColumn}>
        <li className={title ? Styles.dataColumnTitle : Styles.columnItem}>
          {sport}
        </li>
      </ul>

      <ul className={Styles.dataColumn}>
        <li className={title ? Styles.dataColumnTitle : Styles.columnItem}>
          {competence}
        </li>
      </ul>

      <ul className={Styles.dataColumn}>
        <li className={title ? Styles.dataColumnTitle : Styles.columnItem}>
          {from}
        </li>
      </ul>
      
      <ul className={Styles.dataColumn}>
        <li className={title ? Styles.dataColumnTitle : Styles.columnItem}>
          {to}
        </li>
      </ul>

      <ul className={Styles.dataColumn}>
        <li className={title ? Styles.dataColumnTitle : Styles.columnItem}>
          {title ? (
            ""
          ) : (
            <button
              className={Styles.buttonDelete}
              onClick={() => handeleClickDeletedItem(object)}
            >
              {deleted}
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};


export default ColumnItem;
