import React from 'react';
import Styles from './InfomationPopup.module.css';

export default function InfomationPopup({ textInformationPopup, close, lifeTime }) {
  setTimeout(() => {
    close();
  }, lifeTime * 1000);
  const success = textInformationPopup === 'success delete';
  return (
    <div>
      <div className={Styles.container}>
        <span className={success ? Styles.success : Styles.error}> {textInformationPopup} </span>
        <button
          onClick={() => {
            close();
          }}
          className={Styles.closeButton}
        >
          X
        </button>
      </div>
    </div>
  );
}
