import axios from 'axios';
import get from 'lodash/get';
// export const API_URL = 'https://endpoint.triflect.nl/api'
export const API_URL = 'https://triflect.glamorousgoat.com/api'
// export const API_URL = 'http://localhost:10717/api'
const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const IGNORED_UNAUTHORIZED_ROUTES = ["/login_dash"];
const UNAUTHORIZED_STATUS = 401;

const handleUnauthorized = (error) => {
  const status = get(error, "response.status");
  const url = get(error, "request.responseURL", "");

  if (
    status === UNAUTHORIZED_STATUS &&
    !IGNORED_UNAUTHORIZED_ROUTES.find((r) => url.includes(r))
  ) {
    window.localStorage.clear();
    window.location.reload();
  }
};

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    handleUnauthorized(error);
    return Promise.reject({
      extended: get(error, "response.data.error.extended", {}),
      message: get(error, "response.data.error.message", error),
      code: get(error, "response.data.error.errcode", 0),
    });
  }
);

instance.interceptors.request.use((req) => {
  const token = localStorage.tokenData;
  if (token) {
    // eslint-disable-next-line
    req.headers["Authorization"] = `Bearer ${token}`;
  }
  return req;
});
export default instance;
