import React, {  useState, useEffect, useCallback, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import SelectionButton from '../../components/SelectionButton';
import ColumnItem from '../../components/ColumnItem/ColumnItem';
import DropdownButton from '../../components/DropdownButton';
import Titlewrapper from '../../components/Titlewrapper';
import HistoryPopup from '../../components/HistoryPopup/HistoryPopup';
import DeletedPopup from '../../components/DeletedPopup/DeletedPopup';
import BarChart from '../../components/BarChart';
import LineChart from '../../components/LineChart';
import LoadingIndicator from '../../components/LoadingIndicator';
import api from '../../api/APIHelper';
import Styles from './StudentPage.module.css';
import InformationPopup from '../../components/InfomationPopup/InfomationPopup';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { ClassesContext } from '../../context/Classes';

function StudentPage({ match, history, currentUser }) {
  const { classesTeacher } = useSelector((state) => state);
  const { activeClassCheck } = useContext(ClassesContext);
  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [competences, setCompetences] = useState([]);
  const [activeDropdown1, setActiveDropdown1] = useState(false);
  const [activeDropdown2, setActiveDropdown2] = useState(false);
  const [showHistoryPopUp, setShowHistoryPopUp] = useState(false);
  const [showDeletedPopUp, setShowDeletedPopUp] = useState(false);
  const [historyPopupData, setHistoryPopupData] = useState([]);
  const [observationData, setObservationData] = useState([]);
  const [selectedSport, setSelectedSport] = useState({
    name: 'Alle vakken',
    id: 0,
  });
  const [sports, setSports] = useState([{ name: 'Alle vakken', id: 0 }]);
  const [selectedYear, setSelectedYear] = useState(1);
  const [allObservations, setAllObservations] = useState([]);
  const [observations, setObservations] = useState([]);
  const [selectedCompetence, setSelectedCompetence] = useState(0);
  const [rubric, setRubric] = useState([]);
  const [textInformationPopup, setTextInformationPopup] = useState('');
  const [buttonAlle, setButtonAlle] = useState(true);
  const [buttonBeweging, setButtonBeweging] = useState(true);
  const [buttonOmgang, setButtonOmgang] = useState(true);
  const [buttonRegel, setButtonRegel] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [selectedStudentIndex, setSelectedStudentIndex] = useState();
  let showgrag = false;
  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.tokenData}`,
    },
  };

  let student = [];
  if (classesTeacher) {
    classesTeacher.map((item) => {
      if (item.id === activeClassCheck.id) {
        return (student = item);
      }
      return null;
    });
  }
  let filtered =
    student.length === undefined
      ? student.students.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || !search)
      : false;

  const findIndex = () => {
    if (filtered && selectedStudent) {
      filtered.map((student, i) => {
        if (student.name === selectedStudent.name) {
          return setSelectedStudentIndex(i);
        }
        return null
      });
    }
  };
  useEffect(() => {
    findIndex();
  }, [selectedStudent]);

  const observationsFn = useCallback(async (userId) => {
    setIsLoading(true);
    const observations = await api.getObservationsFromUser(userId, headers);
    setObservations(observations);
    setAllObservations(observations);

    let rubricId;

    observations.forEach((item) => {
      item.rubrics.forEach((it) => {
        rubricId = it.rubricId;
      });
    });

    if (rubricId) {
      getRubrickFn(rubricId);
    }
    setIsLoading(false);
    return;
  }, []);

  const selectedUserFn = useCallback(async (userId) => {
    setIsLoading(true);

    const selectedUser = await api.getUserById(userId);
    setSelectedStudent(selectedUser);
    setSelectedYear(selectedUser.year);
    setIsLoading(false);
    return;
  }, []);

  const sportFn = useCallback(async () => {
    setIsLoading(true);

    const sport = await api.getSubjects({ ...headers });
    setSports([...sports, ...sport]);
    setIsLoading(false);
    return;
  }, []);

  const getRubrickFn = useCallback(async (rubricId) => {
    setIsLoading(true);

    const rubric = await api.getRubric(rubricId, headers);
    setRubric(rubric);
    setIsLoading(false);
    return;
  }, []);

  useEffect(() => {
    const userId = match.params.uid;
    observationsFn(userId);
    selectedUserFn(userId);
    sportFn();
  }, [selectedStudentIndex]);

  const dropdown1 = () => {
    setActiveDropdown1(!activeDropdown1);
    setActiveDropdown2(false);
  };

  const dropdown2 = () => {
    setActiveDropdown2(!activeDropdown2);
    setActiveDropdown1(false);
  };

  const showPopup = (object) => {
    if (!showgrag) {
      setShowHistoryPopUp(true);
      setHistoryPopupData(object);
    }
  };

  const hidePopup = () => {
    setShowHistoryPopUp(false);
  };

  const onSetSelectedYear = (selectedItem) => {
    setSelectedYear(selectedItem);
    setActiveDropdown1(false);
  };

  const onSetSelectedSport = async (selectedItem) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.tokenData}`,
      },
    };

    let competenc;
    if (selectedItem.id !== 0) competenc = await api.getCompetencesFromUser(selectedItem.id, headers);
    setSelectedSport(selectedItem);
    setActiveDropdown2(false);
    if (!competenc) {
      setCompetences();
    }
  };

  const handeleClickDeletedItem = (object) => {
    showgrag = true;
    setShowDeletedPopUp(true);
    setObservationData(object);
  };
  const handleClicDone = useCallback(async (object) => {
    setShowDeletedPopUp(false);
    const message = await api.deletedObservation(object);
    await observationsFn(object.studentId);
    setTextInformationPopup(message);
  }, []);

  const Exporteer = useCallback(async () => {
    const userId = match.params.uid;
    const { content, subject } = await api.exportStudent(userId);
    saveAs(new Blob([content], { type: 'text/csv;charset=utf-8' }), subject);
  }, []);
  const schoolyears = [1, 2, 3, 4, 5, 6];
  const closePopup = () => {
    setShowDeletedPopUp(false);
  };
  const checkIndex = () => {
    if (filtered && selectedStudentIndex) {
      if (selectedStudentIndex === 0) {
        return 'Styles.buttonPrevStudentNone';
      } else {
        return 'Styles.buttonPrevStudent';
      }
    }
  };
  useEffect(() => {
    checkIndex();
  }, [selectedStudent]);
  const clickPrevStudent = () => {
    filtered &&
      filtered.map((el, i) => {
        if (i === selectedStudentIndex - 1) {
          return setSelectedStudent(el);
        }
        return null
      });
    history.push(`/studentpage/${filtered[selectedStudentIndex - 1].id}`);
  };
  const clickNextStudent = () => {
    filtered &&
      filtered.map((el, i) => {
        if (i === selectedStudentIndex + 1) {
          setSelectedStudent(el);
        }
      });
    history.push(`/studentpage/${filtered[selectedStudentIndex + 1].id}`);
  };

  return (
    <div className={Styles.mainBackground}>
      {showHistoryPopUp && (
        <div>
          <div className={Styles.historyPopup} />
          <HistoryPopup show={showHistoryPopUp} rubric={rubric} data={historyPopupData} onClick={hidePopup} />
        </div>
      )}
      {showDeletedPopUp && (
        <div>
          <div className={Styles.deletePopup} />
          <DeletedPopup
            show={showDeletedPopUp}
            closePopup={closePopup}
            handleClickDone={handleClicDone}
            handleClicCancel={() => {
              setShowDeletedPopUp(false);
              setObservationData([]);
            }}
            observation={observationData}
          />
        </div>
      )}
      {textInformationPopup && (
        <InformationPopup
          textInformationPopup={textInformationPopup}
          close={() => {
            setTextInformationPopup('');
          }}
          lifeTime={4}
        />
      )}

      <Titlewrapper history={history} user={currentUser} />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div
          style={{
            marginLeft: 48,
            marginRight: 48,
            marginBottom: 48,
            marginTop: 36,
            borderRadius: 5,
          }}
        >
          <div>
            <div>
              <FontAwesomeIcon style={{ color: '#27428C' }} icon={faAngleLeft} />
              <button className={Styles.backClasses} onClick={() => history.push('/search-classes')}>
                Terug naar overzicht
              </button>
            </div>

            <div className={Styles.exporteerBlock}>
              <h2 className={Styles.subTitle}>
                Gemiddelde observatieresultaten
              </h2>
              {/*<Button*/}
              {/*  style={{ height: "40px" }}*/}
              {/*  variant="contained"*/}
              {/*  color="primary"*/}
              {/*  onClick={Exporteer}*/}
              {/*>*/}
              {/*  Exporteer*/}
              {/*</Button>*/}
            </div>
            <div className={Styles.cardWithButtons}>
              <div className={Styles.prevStudent}>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    minWidth: '200px',
                  }}
                >
                  {selectedStudentIndex !== 0 && (
                    <button className={Styles.buttonPrevStudent} onClick={clickPrevStudent}>
                      <FontAwesomeIcon style={{ paddingLeft: 25, marginRight: 10 }} icon={faAngleLeft} />
                      {filtered && !!filtered[selectedStudentIndex - 1] && filtered[selectedStudentIndex - 1].name}
                    </button>
                  )}
                </div>

                <h1 className={Styles.title}>
                  {selectedStudent.name} {selectedStudent.class}
                </h1>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    minWidth: '200px',
                  }}
                >
                  {filtered && selectedStudentIndex < filtered.length - 1 && (
                    <button className={Styles.buttonNextStudent} onClick={clickNextStudent}>
                      {filtered && !!filtered[selectedStudentIndex + 1] && filtered[selectedStudentIndex + 1].name}
                      <FontAwesomeIcon style={{ paddingRight: 25, marginLeft: 10 }} icon={faAngleRight} />
                    </button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 25,
                }}
              >
                <button className={Styles.backForClasses} onClick={() => history.push('/search-classes')}>
                  Wijzig klas
                </button>
              </div>
              <div className={Styles.cardLine}></div>
              <div className={Styles.buttonsBlock}>
                <div>
                  <button
                    className={`${buttonAlle ? Styles.buttonAlle2 : Styles.buttonAlle}`}
                    onClick={() => {
                      setButtonAlle(true);
                      setButtonBeweging(true);
                      setButtonOmgang(true);
                      setButtonRegel(true);
                    }}
                  >
                    Alle
                  </button>
                  <button
                    className={`${buttonBeweging && !buttonAlle ? Styles.buttonBeweging2 : Styles.buttonBeweging}`}
                    onClick={() => {
                      setButtonBeweging(true);
                      setButtonAlle(false);
                      setButtonOmgang(false);
                      setButtonRegel(false);
                    }}
                  >
                    Beweging
                  </button>
                  <button
                    className={`${buttonOmgang && !buttonAlle ? Styles.buttonOmgang2 : Styles.buttonOmgang}`}
                    onClick={() => {
                      setButtonOmgang(true);
                      setButtonAlle(false);
                      setButtonBeweging(false);
                      setButtonRegel(false);
                    }}
                  >
                    Omgang
                  </button>
                  <button
                    className={`${buttonRegel && !buttonAlle ? Styles.buttonRegel2 : Styles.buttonRegel}`}
                    onClick={() => {
                      setButtonRegel(true);
                      setButtonBeweging(false);
                      setButtonOmgang(false);
                      setButtonAlle(false);
                    }}
                  >
                    Regel
                  </button>
                </div>
                <div style={{ display: 'flex' }}>
                  <DropdownButton
                    selectedYear={onSetSelectedYear}
                    schoolyearSelector={true}
                    schoolyears={schoolyears}
                    active={activeDropdown1}
                    onClick={dropdown1}
                  >
                    Leerjaar {selectedYear}
                  </DropdownButton>
                  <DropdownButton
                    selectedSport={onSetSelectedSport}
                    schoolyearSelector={false}
                    sports={sports}
                    active={activeDropdown2}
                    onClick={dropdown2}
                  >
                    {selectedSport.name}
                  </DropdownButton>
                </div>
              </div>
            </div>
            <div className={Styles.graphWrapper}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <SelectionButton
                    key="alle"
                    onClick={() => setSelectedCompetence(0)}
                    active={selectedCompetence === 0}
                  >
                    Alle
                  </SelectionButton>
                  {competences.map((object, index) => (
                    <SelectionButton
                      key={index}
                      onClick={() => setSelectedCompetence(object.id)}
                      active={object.id === selectedCompetence}
                    >
                      {object.name === 'Alle' ? object.name : object.name}
                    </SelectionButton>
                  ))}
                </div>
              </div>
              <div className={Styles.graph}>
                {selectedSport.name === 'Alle vakken' ? (
                  <LineChart
                    observations={allObservations}
                    selectedCompetence={selectedCompetence}
                    studentName={selectedStudent.name}
                    selectedYear={selectedYear}
                    buttonAlle={buttonAlle}
                    buttonBeweging={buttonBeweging}
                    buttonOmgang={buttonOmgang}
                    buttonRegel={buttonRegel}
                  />
                ) : (
                  <BarChart
                    observations={allObservations}
                    studentName={selectedStudent.name}
                    selectedSport={selectedSport}
                    selectedYear={selectedYear}
                    selectedCompetence={selectedCompetence}
                  />
                )}
              </div>
            </div>
            <h2 className={Styles.subTitle}>Observatie geschiedenis</h2>
            <div className={Styles.historyResults}>
              <ColumnItem
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: 14,
                  backgroundColor: 'white',
                  marginTop: 12,
                  borderBottom: '1px solid black',
                  minWidth: '1200px',
                }}
                title={true}
                date={'Datum'}
                sport={'Sport'}
                competence={'Competence'}
                from={'From'}
                method={'Method'}
                to={'To'}
                delted={'Deleted'}
              />
              {allObservations.map((object, i) => {
                return (
                  <ColumnItem
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: 14,
                      cursor: 'pointer',
                      color: 'black',
                      borderBottom: '1px solid black',
                      minWidth: '1200px',
                    }}
                    title={false}
                    key={`allObservationsd${i}`}
                    date={object.date}
                    onClick={() =>
                      showPopup({
                        competence: competences.filter((c) => c.id === object.competenceId).map((item) => item.name),
                        sportName: object.subjectName,
                        ...object,
                      })
                    }
                    object={object}
                    handeleClickDeletedItem={handeleClickDeletedItem}
                    sport={object.componentTitle}
                    competence={object.competenceTitle}
                    from={object.userName}
                    to={object.studentName}
                    deleted={<FontAwesomeIcon className={Styles.buttonDelete} icon={faTrashAlt} />}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(StudentPage);
