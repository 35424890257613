import React from 'react';
import Styles from './HistoryPopup.module.css'

const HistoryPopup = ({ data, show, rubric, onClick }) => {

	const setColor = (param) => {
		switch (param) {
			case 1:
				return '#878787';
			case 2:
				return '#7DE1FE';
			case 3:
				return '#27428C';
			case 4:
				return '#81F6C3';
			default:
			return null
		}
	}
	const setBackground = (param) => {
		switch (param) {
			case 1:
				return 'rgba(135, 135, 135, 0.3)';
			case 2:
				return 'rgba(125, 225, 254, 0.3)';
			case 3:
				return 'rgba(39, 66, 140, 0.3)';
			case 4:
				return 'rgba(129, 246, 195, 0.3)';
			default:
			return null
		}
	}

	// const selectedLevelDescription = (param) => {
	// 	switch (param) {
	// 		case 1:
	// 			return 'level1';
	// 		case 2:
	// 			return 'level2';
	// 		case 3:
	// 			return 'level3';
	// 		case 4:
	// 			return 'level4';
	// 		default:
	// 			return null
	// 	}
	// }
	// const sport = data.sportName[0];

	return (
		<div className={Styles.container}>
			{show === true &&
				<div className = { Styles.background}>
					<div className = { Styles.headerTitle}>
						<h1 className = { Styles.title}>Observatiedetails</h1>
						<button className = { Styles.button} onClick={onClick} >X</button>
					</div>

					{/* <Moment style={subTitle} format="DD/MM" >{data.timestamp.seconds}</Moment> */}

					<div className = {Styles.containerInformation}>
						{/* <p>{sport.length > 20 ? sport.substring(0, 20) + "..." : sport.substring(0, sport.length)}</p> */}
						<p>{data.componentTitle}</p>
						<p>{data.competenceTitle}</p>
						<p>van: {data.userName}</p>
						<p>aan: {data.studentName}</p>
					</div>
					
					{/* <div style={{ bottom: 0, width: '95%', height: 100, background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.8))', opacity: 0.5, position: 'absolute', zIndex: 101 }}></div> */}
					
					<div className={Styles.containerObservation}>
						{rubric.levels.map(
							(level, index) => {
								let chosenIndex = false
								data.rubrics.map((rubric)=>{
									if(data.rubrics[0].levelValue===level.levelValue){
										chosenIndex=true
									}
									return rubric
								})
								return (
									<div key={index} >
										<div className={`${Styles.objectTitle} ${chosenIndex&&Styles.chosenIndex}`} style={{ background: setBackground(level.levelValue), color: setColor(level.levelValue) }}>
											{level.title} &nbsp; 
											{/* <div style={{ color: setColor(level.levelValue) }}> 
												{level.open === true ? level.question : `Niveau ${level.levelValue}`}
											</div> */}
										</div>
	
										<div className={Styles.objectAnswer}>
											{level.open === true ? level.answer : level.description}
										</div>
									</div>
								)
							}
						)}

					</div>
				</div>
			}

		</div>
	);
}

export default HistoryPopup;