import React, { useEffect, useState } from 'react';
import api from '../../api/APIHelper';
import Styles from './SportPopup.module.css';
import RowInputs from './RowInputs';
import {API_URL}from '../../api/index'

export default function SportPopup(props) {
  const defaultData = { description: '', type: '', url: '' };
  const [data, setData] = useState([]);
  const [editableData, setEditableData] = useState([defaultData]);
  const [disabledOpslaan, setDisabledOpslaan] = useState(true);
  const headers = {
    headers: { Authorization: `Bearer ${localStorage.tokenData}` },
  };

  const addNewLink = () => {
    setEditableData([...editableData, defaultData]);
  };

  const afterDidMount = async () => {
    let sportLinks = '';
    props.components.forEach((item) => {
      if (item.id === props.activeSport.id) {
        sportLinks = item.links === null ? (item.links = []) : JSON.parse(item.links);
      }
    });
    setData(sportLinks);
  };

  useEffect(() => {
    if (data.length) {
      setEditableData(data);
    } else {
      setEditableData([{ description: '', type: '', url: '' }]);
    }
  }, [data]);

  useEffect(() => {
    afterDidMount();
  }, []);

  const changeData = (row, i) => {
    let newa = [...editableData];
    newa[i] = row;
    setEditableData(newa);
  };

  const sentRequest = async () => {
    const data = await checkAndSentPdf();
    const res = await api.createLinks(data, headers, props.activeSport.id);
    setEditableData(res);
  };
  const downloadPDF = async (name) => {
    let link = document.createElement('a');
    link.href =  name
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent('click'));
  };
  const checkAndSentPdf = async () => {
    const dataWithPdf = editableData.filter((row, i) => {
      return typeof row.url === 'object';
    });
    if (!dataWithPdf.length) {
      return editableData;
    }

    const res = [];

    for (let i = 0; i < editableData.length; i++) {
      let obj = editableData[i];
      if (typeof obj.url !== 'object') {
        res.push(obj);
      } else {
        const urlLink = await api.createPDFforLinks(obj.url.file, props.activeSport.id);
        obj.url = urlLink;
        res.push(obj);
      }
    }
    return res;
  };
  useEffect(() => {
    editableData.map((el) => {
      if (!el.description || !el.type || !el.url) {
        return setDisabledOpslaan(true);
      } else {
        return setDisabledOpslaan(false);
      }
    });
  }, [editableData]);

  const deleteRowInputs = (index) => {
    const item = editableData.splice(index, 1);
    const newArr = editableData.map((el) => {
      if (item.description !== el.description && item.type !== el.type && item.url !== el.url) {
        return el;
      }
    });
    setEditableData(newArr);
  };
  return (
    <div>
      {props.show === true && (
        <div className={Styles.background}>
          <div className={Styles.main}>
            <div>
              <h1 className={Styles.title}>{props.data.name}</h1>
            </div>
            <div>
              {editableData.map((row, i) => {
                return (
                  <RowInputs
                    key={`${i}RowInputs`}
                    row={row}
                    index={i}
                    changeData={(row, i) => changeData(row, i)}
                    deleteRow={deleteRowInputs}
                    downloadPDF={downloadPDF}
                  />
                );
              })}
              <div className={Styles.buttonsBlock}>
                {editableData.length < 3 && (
                  <button className={Styles.addButtonActive} onClick={() => addNewLink()}>
                    +
                  </button>
                )}
                <button
                  className={disabledOpslaan ? Styles.saveButtonDisabled : Styles.saveButton}
                  onClick={() => sentRequest()}
                  disabled={disabledOpslaan}
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
