import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
      <div style={sideNav}>
        <div style={logo}>
          <h1 style={title}>Logo</h1>
        </div>
        <NavLink style={link} activeStyle={activeLink} exact to="/">
          Mijn overzicht
        </NavLink>
        {/* <NavLink style={link} activeStyle={activeLink} exact to="/rubrics">Rubrics aanmaken</NavLink> */}
        <NavLink style={link} activeStyle={activeLink} exact to="/rubrics2">
          Rubrics
        </NavLink>
        <NavLink style={link} activeStyle={activeLink} exact to="/subjectLinks">
          Subject links
        </NavLink>
        <NavLink style={link} activeStyle={activeLink} exact to="/relateClasses">
          Relate classes
        </NavLink>
        {/* <NavLink style={link} activeStyle={activeLink} exact to="/instellingen">Instellingen</NavLink> */}
      </div>
    );
}

const logo = {
    width: '100%',
    backgroundColor: 'white',
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    color: 'black',
    justifyContent: 'space-between',
    marginBottom: 48
}

const title = {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 48,
    fontSize: 16,
    opacity: 0.2,
    fontWeight: 500,
    color: 'black',
}

const sideNav = {
    width: '20%',
    minHeight: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid rgba(0,0,0,0.05)',
    fontSize: 16,
}

const activeLink = {
    color: '#4D7CF9',
    borderRight: '2.5px solid #4D7CF9',
    backgroundColor: '#eaf2ff',
    paddingLeft: 45,
    marginLeft: 12,
    marginTop: 12,
    marginBottom: 12,
    fontWeight: 500,

}

const link = {
    marginTop: 12,
    marginLeft: 12,
    marginBottom: 12,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 45,
    fontWeight: 500,
    color: '#B6BAC1',
    textDecoration: 'none'
}


export default Navigation;