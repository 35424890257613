import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClassListItem from "../../ClassListItem";
import PrimaryActionButton from "../../PrimaryActionButton";
import APIHelper from "../../../api/APIHelper";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/actions";
import Styles from "./GetClasses.module.css";
import { useHistory } from "react-router-dom";
import { ClassesContext } from "../../../context/Classes";

const GetClasses = (props) => {
  const history = useHistory();
  const {classSelection, setClassSelection} = useContext(ClassesContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state);
  const [search, setSearch] = useState("");
  // const [classes, setClasses] = useState([]);
  useEffect(() => {
    if (localStorage.tokenData) {
      const token = localStorage.tokenData;
      getAllClassesForTeacher(token);
    }
  }, []);

  const getAllClassesForTeacher = async (token) => {
    let classes = await APIHelper.getAllClassesForTeacher({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (classes) {
      classes = classes
        .map((item) => ({ ...item, checked: false }))
        .sort((a, b) => {
          if (a.className > b.className) {
            return 1;
          }
          if (a.className < b.className) {
            return -1;
          }
          return 0;
        });
      // setClasses(classes);
      setClassSelection(classes);
    }
  };

  // const classeGetClasses = () => {
  //   dispatch(actions.showPopup(false));
  // };

  // useEffect(() => {
  //   classSelection.map((item) => {
  //     if (item.checked) {
  //       setClassSelection([...classSelection]);
  //     }
  //   });
  // }, [classSelection]);
 

  const saveClasses = () => {
    dispatch(actions.postClassesId(classSelection));
    dispatch(actions.showPopup(false));
    history.push("/search-classes");
  };
  let filtered = classSelection.filter(
    (item) =>
      item.className.toLowerCase().indexOf(search.toLowerCase()) > -1 || !search
  );

  return (
    <div>
      {user.showPopup === true ? (
        <div className={Styles.background}>
          <div className={Styles.main}>
            <div className={Styles.title}>
              <h1>Welkom.</h1>
            </div>
            <p>
              Selecteer alle klassen die voor jou relevant zijn. Deze kunnen
              altijd nog gewijzigd worden.
            </p>
            <div className={Styles.input}>
              <FontAwesomeIcon
                style={{
                  paddingLeft: 12,
                  paddingTop: 12,
                  paddingRight: 0,
                  paddingBottom: 12,
                  color: "#BFBFBF",
                }}
                icon={"search"}
              />
              <input
                type="search"
                value={search}
                placeholder="Zoek klas"
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  width: "100%",
                  height: 40,
                  border: "none",
                  padding: 12,
                  fontSize: 12,
                  outline: 0,
                  opacity: 0.6,
                }}
              />
            </div>

            <div className={Styles.classesConteiner}>
              {search.length > -1 && (
                <ul>
                  {classSelection &&
                    filtered.map((item, index) => (
                      <ClassListItem
                        active={item.checked}
                        onClick={() =>
                          setClassSelection(
                            classSelection.map((it) => {
                              if (it.id === item.id)
                                return { ...it, checked: !it.checked };

                              return it;
                            })
                          )
                        }
                        key={index}
                      >
                        {item.className}
                      </ClassListItem>
                    ))}
                </ul>
              )}
            </div>
            <div>
              <PrimaryActionButton
                activebutton={classSelection.length < 1 ? false : true}
                onClick={saveClasses}
              >
                Opslaan
              </PrimaryActionButton>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
// }

export default GetClasses;
