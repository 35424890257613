import React from 'react';

const SelectionButton = ({ addButton, active, onClick, children }) => (
    <button style={addButton ? styles.addButton : active ? styles.active : styles.inactive} onClick={onClick}>
        <p>{children}</p>
    </button>
);

const styles = {

    active: {
        backgroundColor: '#D8E6FF',
        color: '#576BFF',
        border: 'none',
        borderRadius: 5,
        fontSize: 15,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 24,
        marginRight: 12,
        outline: 0,
        fontWeight: 400,
        cursor: "pointer"
    },

    inactive: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.05)',
        color: '#B6BAC1',
        borderRadius: 5,
        fontSize: 15,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 24,
        marginRight: 12,
        outline: 0,
        fontWeight: 300,
        cursor: "pointer"
    },

    addButton: {
        backgroundColor: '#F0F0F0',
        color: '#B5B5B5', 
        borderRadius: 5,
        fontSize: 14,
        paddingLeft: 24,
        paddingRight: 24,
        marginBottom: 24,
        marginRight: 12,
        fontWeight: 500,
        outline: 0,
        border: 'none'
    }
}

export default SelectionButton;