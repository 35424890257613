import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";

const Titlewrapper = (props, { user = { name: "" } }) => {
  const userInfo = useSelector((state) => state.userInfo);
  const { name } = userInfo;
  const logout = () => {
    localStorage.clear("tokenData");
    localStorage.setItem("login", false);
    document.cookie =
      "logged_in=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    props.history.go("/login_dash");
  };

  return (
    <div style={styles.wrapper}>
      <h3 style={styles.title}>Dashboard {user.college} college</h3>
      <div style={{ display: "flex", alignItems: "center", marginRight: 48 }}>
        <div style={styles.profilePicture}>
          {name ? name.match(/\b(\w)/g) : false}
        </div>
        <p>{name}</p>
        <button style={styles.button} onClick={logout}>
          Log uit
        </button>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    backgroundColor: "white",
    paddingTop: 12,
    paddingBottom: 12,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0,0,0,0.05)",
    color: "black",
    justifyContent: "space-between",
  },
  title: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 48,
    fontSize: 16,
    fontWeight: 500,
    color: "black",
  },
  profilePicture: {
    textTransform: "uppercase",
    color: "#576BFF",
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 5,
    fontWeight: 500,
    backgroundColor: "#D8E6FF",
    marginRight: 12,
  },
  button: {
    backgroundColor: "white",
    border: "1px solid rgba(0,0,0,0.05)",
    color: "#B6BAC1",
    borderRadius: 5,
    fontSize: 15,
    padding: 12,
    marginLeft: 12,
    marginRight: 12,
    outline: 0,
    fontWeight: 300,
    cursor: "pointer",
  },
};

export default withRouter(Titlewrapper);
