import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronUp,
  faUserFriends,
  faRunning,
  faCog,
  faGraduationCap,
  faDownload,
  faPlus,
  faSearch,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { SnackbarProvider } from "notistack";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Rubrics2 from "./pages/Rubrics2/Rubrics2";
import OurPage from './components/OurPage/OurPage'
import DataBase from "./components/DataBaseReq/DataBase";
import Sports from "./pages/Sports/Sports";
import Error from "./components/Error";
import Navigation from "./components/Navigation";
import StudentPage from "./pages/StudentPage";
import Login from "./pages/Login";
import Overzicht from "./pages/Overview";
import TeacherLoginRedirect from "./pages/TeacherLoginRedirect";
import { useSelector, useDispatch } from "react-redux";
import APIHelper from "./api/APIHelper";
import actions from "./store/actions";
import GetClasses from "./components/firebase/GetClasses/GetClasses";
import {ClassesProvider} from './context/Classes'
import RelateClasses from "./pages/RelateClasses/RelateClasses";

library.add(
  faChevronDown,
  faChevronUp,
  faUserFriends,
  faRunning,
  faCog,
  faGraduationCap,
  faDownload,
  faPlus,
  faSearch,
  faExchangeAlt
);

const App = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#4E7CF9",
      },
    },
  });
  useEffect(() => {
    if (localStorage.tokenData) {
      const token = localStorage.tokenData;
      userInfo(token);
      dispatch(actions.postUser(token));
    }
  }, []);
  const userInfo = async (token) => {
    const user = await APIHelper.userInfo({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (user) {
      dispatch(actions.userInfo(user));
    }
  };
  return (
    <ClassesProvider>
      <SnackbarProvider
        preventDuplicate
        maxSnack={2}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div
              style={{
                display: "flex",
                minHeight: "100vh",
                backgroundColor: "#F3F5F7",
              }}
            >
              {!(!currentUser || !localStorage.tokenData) && <Navigation />}
              <Switch>
              <Route
                  history={props.history}
                  
                  path="/teacherLogin"
                  component={TeacherLoginRedirect}
                />
                {(!currentUser || !localStorage.tokenData) ? (
                  <Route history={props.history} path="/" component={Login} />
                ) : (
                  <Redirect path="/login_dash" to="/" />
                )}
                <Route
                  history={props.history}
                  path="/subjectLinks"
                  render={(routeProps) => (
                    <Sports {...routeProps} currentUser={currentUser} />
                  )}
                />
                <Route
                  history={props.history}
                  path="/relateClasses"
                  render={(routeProps) => (
                    <RelateClasses {...routeProps} currentUser={currentUser} />
                  )}
                />
                <Route
                  history={props.history}
                  path="/studentpage/:uid"
                  render={(routeProps) => (
                    <StudentPage
                      {...routeProps}
                      history={props.history}
                      currentUser={currentUser}
                    />
                  )}
                />
                <Route
                  history={props.history}
                  path="/rubrics2"
                  render={(routeProps) => (
                    <Rubrics2 {...routeProps} currentUser={currentUser} />
                  )}
                />
                <Route
                  history={props.history}
                  path="/addeverything"
                  render={(routeProps) => (
                    <OurPage {...routeProps} currentUser={currentUser} />
                  )}
                />
                <Route
                  history={props.history}
                  path="/get-all"
                  render={(routeProps) => (
                    <DataBase {...routeProps} currentUser={currentUser} />
                  )}
                />
                {/* <Route path='/instellingen' component={Settings} /> */}
                
               
                {currentUser && (
                  <Route
                    history={props.history}
                    exact
                    path="/"
                    component={GetClasses}
                  />
                )}
                {currentUser && (
                  <Route
                    history={props.history}
                    exact
                    path="/search-classes"
                    component={Overzicht}
                  />
                )}

                <Route component={Error} />
              </Switch>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </ClassesProvider>
  );
};

export default App;
