import React from 'react';
import { NavLink } from 'react-router-dom';
import Styles from "./StudentListItem.module.css";


const StudentListItem = ({ student, onMouseEnter }) => {

  return (
    <NavLink
      className={Styles.navLink}
      to={`/studentpage/${student.id}`}
      activeClassName="active"
    >
      <div className={Styles.divItem}>
        <li className={Styles.listItem}>{student.name}</li>
      </div>
    </NavLink>
  );
};

export default StudentListItem;
