import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";
import CSVReader1 from "./ChooseFile";

export default function FormDialog({ onAddStudents, sendStudents }) {
  const [open, setOpen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [addStudents, setAddStudents] = useState({
    data: [],
    separator: "",
  });

  useEffect(() => {
    onAddStudents(addStudents);
  }, [addStudents]);

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    underlineInput: {
      "& .MuiInputBase-root": {
        "& input": {
          boxShadow: "none",
          borderBottom: "none",
          paddingLeft: "15px",
          marginBottom: 0,
          "&:focus": {
            borderBottom: "none !important",
            boxShadow: "none !important",
          },
        },
      },
    },
  }));

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const separatorValue = event.target.value;
    setAddStudents({
      ...addStudents,
      separator: separatorValue,
    });
  };

  const reqTech = () => {
    sendStudents();
    setOpen(false);
  };

  const setData = (data) => {
    let arr = [...data];
    arr.pop();
    arr.shift();
    setDataTable(arr);
  };

  const getFile = (file) => {
    const firstFile = file;
    const formData = new FormData();
    formData.append("file", firstFile);
    setAddStudents({
      ...addStudents,
      data: formData,
    });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Добавить студента
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" style={{ marginLeft: "25px" }}>
          Добавление студента
        </DialogTitle>
        <DialogContent>
          <Container maxWidth="md" className={classes.root}>
            <form
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
            >
              <div>
                <CSVReader1 dataTable={setData} setChooseFile={getFile} />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>Выбрать сепаратор:</p>
                <select onChange={handleChange} style={{ marginLeft: "10px" }}>
                  <option></option>
                  <option>;</option>
                  <option>,</option>
                </select>
              </div>
            </form>
            <div>
              <table border="1" width="100%" cellPadding="5">
                <tr>
                  <th>Stam</th>
                  <th>Name</th>
                  <th>Year</th>
                  <th>classStudent</th>
                </tr>
                {dataTable.map((item) => (
                  <tr>
                    <td>{item.data[0]}</td>
                    <td>{item.data[1]}</td>
                    <td>{item.data[2]}</td>
                    <td>{item.data[3]}</td>
                  </tr>
                ))}
              </table>
            </div>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <button onClick={reqTech}>Отправить файл</button>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
