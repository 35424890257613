import React, { useEffect, useState, useContext } from "react";
import SelectionButton from "../components/SelectionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Titlewrapper from "../components/Titlewrapper";
import GetUsers from "../components/firebase/GetUsers";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import api from "../api/APIHelper";
import { ClassesContext } from "../context/Classes";
import { useHistory } from "react-router-dom";
import { saveAs } from 'file-saver';

const Overzicht = (props) => {
  // const user = useSelector((state) => state);
  // const { classesId } = useSelector((state) => state)
  // const [activeClass, setActiveClass] = useState("");
  const [classes, setClasses] = useState([]);
  const {checkExport, setCheckExport} = useContext(ClassesContext);
  const {classSelection} = useContext(ClassesContext);
  const { activeClassCheck, setActiveClassCheck } = useContext(ClassesContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.tokenData}`,
    },
  };
  const userInfo = async (headers) => {
    const user = await api.userInfo({ ...headers });
    if (user) {
      dispatch(actions.userInfo(user));
    }
  };
  useEffect(() => {
    userInfo(headers);
    dispatch(actions.showPopup(true));
    getCheckedClasses();
  }, []);
  const getClassesTeacher = async (payload, headers) => {
    const classesTeacher = await api.getClassesTeacher(payload, { ...headers });

    classesTeacher.map((classItem) => {
      const studentsSort = classItem.students.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else {
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      return { ...classItem, students: studentsSort };
    });

    if (classesTeacher) {
      setClasses(classesTeacher);
      dispatch(actions.postClassesTeacher(classesTeacher));
    }
  };
  const newArr = classSelection.filter((item) => item.checked === true);
  const newArr2 = newArr.map((item) => item.id);

  const getCheckedClasses = () => {
    if (newArr2.length > 0) {
      const a = new URLSearchParams();
      a.append("ids", [newArr2]);
      getClassesTeacher(a.toString(), { ...headers });
      dispatch(actions.postClassesId([]));
    }
  };

  // const openGetClasses = () => {
  //   setCheckExport(true);
  //   dispatch(actions.showPopup(true));
  // };
  const returnBack = ()=>{
    history.push("/")
  }

  const Exporteer =  async (activeClassCheck) => {
    const {content,subject } = await api.exportClass(activeClassCheck.id);
    saveAs(new Blob([content], { type: 'text/csv;charset=utf-8' }), subject)
  }


  return (
    <div style={mainBackground}>
      <Titlewrapper history={props.history} user="user" />
      <div style={{ margin: 48, borderRadius: 5 }}>
        <div>
          <h1 style={title}>Mijn overzicht</h1>
          <h2 style={subTitle}>Klassen</h2>
          <div style={classWrapper}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {classes.map((item) => (
                <SelectionButton
                  key={item.className}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    // setActiveClass(item);
                    setActiveClassCheck(item);
                    setCheckExport(false);
                  }}
                  active={activeClassCheck.id === item.id}
                >
                  {item.className}
                </SelectionButton>
              ))}
              <div style={{ display: "flex", height: 78 }}>
                <button
                  disabled={checkExport}
                  onClick={() => Exporteer(activeClassCheck)}
                  style={checkExport ? exportButtonActive : exportButton}
                >
                  <FontAwesomeIcon
                    disabled={checkExport}
                    style={{ paddingRight: 12 }}
                    icon={"download"}
                  />
                  Exporteer klasresultaten
                </button>
                <button onClick={()=>returnBack()} style={buttonReturn}>
                  <FontAwesomeIcon
                    style={{ paddingRight: 12 }}
                    icon={"exchange-alt"}
                  />
                  Wijzig klas
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 48 }}>
          <h2 style={subTitle}>Leerlingen</h2>
          <div style={studentsWrapper}>
            <GetUsers activeClassCheck={activeClassCheck} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mainBackground = {
  width: "100%",
  backgroundColor: "#F9F9FB",
};

const subTitle = {
  fontSize: 20,
  fontWeight: 300,
  color: "#A4A3AD",
};

const classWrapper = {
  display: "flex",
  backgroundColor: "white",
  paddingLeft: 24,
  paddingTop: 24,
  border: "1px solid rgba(0,0,0,0.05)",
  // boxShadow: `0px 0px 50px rgba(0,0,0,0.085)`,
  borderRadius: 5,
};

const title = {
  fontSize: 30,
  fontWeight: 600,
  color: "#17233E",
  marginBottom: -15,
};

const studentsWrapper = {
  height: 400,
  padding: 24,
  backgroundColor: "white",
  alignContent: "flex-start",
  border: "1px solid rgba(0,0,0,0.05)",
  // boxShadow: `0px 0px 25px rgba(0,0,0,0.05)`,
  borderRadius: 5,
};

const exportButton = {
  backgroundColor: "#4D7CF9",
  color: "white",
  border: "none",
  borderRadius: 5,
  fontSize: 14,
  paddingLeft: 24,
  paddingRight: 24,
  marginBottom: 24,
  marginRight: 24,
  fontWeight: 500,
  outline: 0,
  cursor: "pointer",

  // boxShadow: `0px 0px 25px rgba(0,0,0,0.20)`
};
const exportButtonActive = {
  backgroundColor: "#F8F9FD",
  color: "#70859D",
  border: "none",
  borderRadius: 5,
  fontSize: 14,
  paddingLeft: 24,
  paddingRight: 24,
  marginBottom: 24,
  marginRight: 24,
  fontWeight: 500,
  outline: 0,
  // boxShadow: `0px 0px 25px rgba(0,0,0,0.20)`
};
const buttonReturn = {
  backgroundColor: "#4D7CF9",
  color: "white",
  border: "none",
  borderRadius: 5,
  fontSize: 14,
  paddingLeft: 24,
  paddingRight: 24,
  marginBottom: 24,
  marginRight: 24,
  fontWeight: 500,
  outline: 0,
  cursor: "pointer"
  // boxShadow: `0px 0px 25px rgba(0,0,0,0.20)`
};



export default Overzicht;
