import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';

const NOT_SPECIFIED = 'Not specified';

class BarChart extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React',
      data: {
        labels: [],
        datasets: [],
      },
    };
  }
  componentDidMount() {
    this.handleGraphUpdate(this.props.selectedYear);
  }

  componentDidUpdate(prevProps, prevState) {


    if (
      this.props.selectedSport.id !== prevProps.selectedSport.id ||
      this.props.selectedYear !== prevProps.selectedYear ||
      this.props.selectedCompetence !== prevProps.selectedCompetence
    ) {
      this.handleGraphUpdate(this.props.selectedYear);
    }
  }
  handleGraphUpdate(selectedYear) {
    const formattedObservations = this.props.observations.filter(o => {
      const filterByCompetence = this.props.selectedCompetence ? this.props.selectedCompetence === o.competenceId : true;
      return filterByCompetence;
    });
    const labels = {};
    formattedObservations.forEach(observationData => {
      observationData.rubrics
        .filter(a => a.rubrickYear == selectedYear)
        .forEach(
          a =>
            (labels[a.rubricId || NOT_SPECIFIED] = {
              sum: 0,
              count: 0,
            })
        );
    });


    this.setState({
      hasData: Object.keys(labels).length > 0,
      data: canvas => {
        const ctx = canvas.getContext('2d');
        const gradient1 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient1.addColorStop(0, 'rgba(59, 103, 252, 0)');
        gradient1.addColorStop(1, 'rgba(59, 103, 252, 0.5)');

        const gradient2 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient2.addColorStop(0, 'rgba(114, 95, 255, 0)');
        gradient2.addColorStop(1, 'rgba(114, 95, 255, 0.5)');

        const gradient3 = ctx.createLinearGradient(0, 500, 0, 0);
        gradient3.addColorStop(0, 'rgba(133, 219, 250, 0)');
        gradient3.addColorStop(1, 'rgba(133, 219, 250, 0.5)');

        const selfObservedByActionType = {};
        const studentObservedByActionType = {};
        const teacherObservedByActionType = {};
        Object.keys(labels).forEach(key => {
          selfObservedByActionType[key] = {
            sum: 0,
            count: 0,
          };
          studentObservedByActionType[key] = {
            sum: 0,
            count: 0,
          };
          teacherObservedByActionType[key] = {
            sum: 0,
            count: 0,
          };
        });

        const getAverageSelectedLevel = (observation, observedBy) => {
          const thisYearsAnswers = observation.rubrics.filter(a => a.rubrickYear == selectedYear)
          if (observedBy === 'self') {
            thisYearsAnswers.forEach(answer => {
              selfObservedByActionType[answer.rubricId || NOT_SPECIFIED].sum += (answer.selectedLevel || 0) + 1;
              selfObservedByActionType[answer.rubricId || NOT_SPECIFIED].count += 1;
            });
          }
          if (observedBy === 'student') {
            thisYearsAnswers.forEach(answer => {
              studentObservedByActionType[answer.rubricId || NOT_SPECIFIED].sum += (answer.selectedLevel || 0) + 1;
              studentObservedByActionType[answer.rubricId || NOT_SPECIFIED].count += 1;
            });
          }
          if (observedBy === 'teacher') {
            thisYearsAnswers.forEach(answer => {
              teacherObservedByActionType[answer.rubricId || NOT_SPECIFIED].sum += (answer.selectedLevel || 0) + 1;
              teacherObservedByActionType[answer.rubricId || NOT_SPECIFIED].count += 1;
            });
          }

        };

        formattedObservations
          .filter(o => o.userId === o.studentId)
          .forEach(o => {
            getAverageSelectedLevel(o, 'self');
          });

        formattedObservations
          .filter(o => o.userRole === 'student' && o.userId !== o.studentId)
          .forEach(o => {
            getAverageSelectedLevel(o, 'student');
          });

        formattedObservations
          .filter(o => o.userRole === 'teacher')
          .forEach(o => {
            getAverageSelectedLevel(o, 'teacher');
          });

        const datasets = [
          {
            label: 'Zelfreflectie',
            data: Object.keys(selfObservedByActionType).map(
              type => selfObservedByActionType[type].sum / selfObservedByActionType[type].count || 0
            ),
            hidden: false,
            borderWidth: 1.5,
            borderColor: 'rgba(59, 103, 252, 1)',
            backgroundColor: gradient1,
          },
          {
            label: 'Klasgenoot',
            data: Object.keys(studentObservedByActionType).map(
              type => studentObservedByActionType[type].sum / studentObservedByActionType[type].count || 0
            ),
            hidden: false,
            borderWidth: 1.5,
            borderColor: 'rgba(114, 95, 255, 1)',
            backgroundColor: gradient2,
          },
          {
            label: 'Docent',
            data: Object.keys(teacherObservedByActionType).map(
              type => teacherObservedByActionType[type].sum / teacherObservedByActionType[type].count || 0
            ),
            hidden: false,
            borderWidth: 1.5,
            borderColor: 'rgba(133, 219, 250, 1)',
            backgroundColor: gradient3,
          },
        ];
      

        return {
          labels: Object.keys(labels).map(item => (item.length > 45 ? item.substring(0, 45) + '...' : item.substring(0, item.length))),
          datasets,
        };

      },
      
    });

  }

  render() {

    return (
      <div style={{ height: '100%', position: 'relative', zIndex: 1 }}>
        <p>{this.props.title}</p>
        {/* {this.state.hasData ? ( */}
        <Bar
          data={this.state.data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                fontColor: '#B0B0B0',
                boxWidth: 5,
                padding: 20,
                fontSize: 12,
                usePointStyle: true,
              },
            },
            elements: {
              point: {
                radius: 1,
              },
            },
            scales: {
              yAxes: [
                {
                  gridLines: {
                    // You can change the color, the dash effect, the main axe color, etc.
                    borderDash: [5, 15],
                    color: '#e8e8e8',
                  },
                  ticks: {
                    stepSize: 1,
                    max: 4,
                    min: 0,
                  },
                },
              ],
              xAxes: [
                {
                  categoryPercentage: 0.35,
                  barPercentage: 0.8,
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />

       {/* ) : (
          <div>No data.</div> */}
        {/* )} */}
      </div>
    );
  }
}

export default BarChart;
