import api from ".";

const API_URL_AUTH = `/auth`;
const API_URL_TEACHER = `/teacher`;

async function userInfo(token) {
  const { data: tokenUser } = await api.get("/user");
  return tokenUser;
}
async function Login(email, password) {
  const res = await api.post(API_URL_AUTH + `${"/login"}`, {
    email,
    password,
  });
  const token = new URL(res.request.responseURL).searchParams.get("token");
  return token;
}
async function getAllClassesForTeacher(token) {
  const { data: classes } = await api.get(
    API_URL_TEACHER + `${"/classes"}`,
    token
  );
  return classes;
}
async function changeClassesForTeacher(headers) {
  const { data: newData } = await api.put(
    API_URL_TEACHER + `${"/classes"}`,
    headers
  );
  return newData;
}
async function getAllStudentsForTeacher(headers) {
  const { data: newData } = await api.get(
    API_URL_TEACHER + `${"/students"}`,
    headers
  );
  return newData;
}
async function getClassesTeacher(payload) {
  const { data: newData } = await api.get("/classes/classes-info?" + payload);
  return newData;
}
async function getObservationsFromUser(id) {
  const { data: newData } = await api.get("/observations?studentId=" + id);
  return newData;
}
async function getCompetencesFromUser(id, year) {
  const { data: newData } = await api.get(
    `/competences?subjectId=${id}&year=${year}`
  );
  return newData;
}
async function getUserById(id) {
  const { data: newData } = await api.get(`/user/${id}`);
  return newData;
}
async function getSubjects() {
  const { data: newData } = await api.get(`/subjects`);
  return newData;
}
async function getAllCompetences() {
  const { data: newData } = await api.get(`/allCompetences`);
  return newData;
}
async function getComponents(id, year) {
  const { data: newData } = await api.get(
    `/components?subjectId=${id}&year=${year}`
  );
  return newData;
}
async function getRubrics(competenceId, componentId, year) {
  const { data: newData } = await api.get(
    `/rubrics?competenceId=${competenceId}&componentId=${componentId}&year=${year}`
  );
  return newData;
}
async function postRubric(rubricData) {
  const { data: newData } = await api.post(`/rubric`, {
    ...rubricData,
  });
  return newData;
}
async function getRubricsCategories() {
  const { data: newData } = await api.get(`/rubric/categories`);
  return newData;
}
async function getRubric(rubricId) {
  const { data: newData } = await api.get(`/rubric?rubricId=${rubricId}`);
  return newData;
}
async function createLinks(data, headers, componentId) {
  const {
    data: newData,
  } = await api.post(`/createLinks?componentId=${componentId}`, { data });
  return newData;
}
async function createPDFforLinks(pdf, competenceId){
  let formData = new FormData();
  formData.append('pdf', pdf);
  const { data: newData } = await api.post(`/createPDFforLinks?competenceId=${competenceId}`, 
  formData,
  {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }
  )
  return newData
}

async function postLevelsRubric(data, headers, rubricId) {
  const {
    data: newData,
  } = await api.post(`/rubricCreateLevels?rubricId=${rubricId}`, { data });
  return newData;
}
async function exportClass(classId) {
  const { data: newData } = await api.get(`/export-class-info/${classId}`);
  return newData;
}
async function exportStudent(studentId) {
  const { data: newData } = await api.get(`/export-student-info/${studentId}`);
  return newData;
}
async function deletedObservation(object) {
  const { data: newData } = await api.delete(`/observation/${object.id}`);
  return newData;
}
async function postTeachers(object) {
  const { data: newData } = await api.post(`/import/teachers`, object);
  return { newData };
}
async function postStudents(object) {
  const { data: newData } = await api.post(`/import/v2/students`, object);
  return { newData };
}
async function getSchools() {
  const data = await api.get(`/get-schools`);
  return data;
}
async function getObservations() {
  const data = await api.get(`/get-observations`);
  return data;
}
async function getStudents() {
  const data = await api.get(`/get-students`);
  return data;
}
async function getSubjectss() {
  const data = await api.get(`/get-subjects`);
  return data;
}
async function getUsers() {
  const data = await api.get(`/get-users`);
  return data;
}
async function getRubricss() {
  const data = await api.get(`/get-rubrics`);
  return data;
}
async function getTeachers() {
  const data = await api.get(`/get-teachers`);
  return data;
}
async function getCompetences() {
  const data = await api.get(`/get-competences`);
  return data;
}
async function getAllComponents() {
  const data = await api.get(`/get-components`);
  return data;
}
async function getLevels() {
  const data = await api.get(`/get-levels`);
  return data;
}
async function getAnsweredRubric() {
  const data = await api.get(`/get-answered-rubric`);
  return data;
}
async function getSchoolClass() {
  const data = await api.get(`/get-school-class`);
  return data;
}
async function testEditValues(prevState, newState, tableName) {
  const data = await api.post(`/edit-values`, {
    prevState,
    newState,
    tableName,
  });
  return data;
}
async function getRelateClasses() {
  const data = await api.get(`/get-relate-classes`);
  return data;
}
async function postRelateClasses(classesId, teacherId, subjectId) {
  const data = await api.post(`/post-relate-classes`, {
    classesId,
    teacherId,
    subjectId,
  });
  return data;
}
async function deleteRelateClasses(id) {
  const { data: newData } = await api.delete(`/delete-relateClasses/${id}`);
  return newData;
}
async function editRelateClasses(oldData, newData) {
  const data = await api.put(`/edit-relate-classes`, {
    oldData,
    newData,
  });
  return data;
}
async function getSubjectTeacher() {
  const data = await api.get(`/get-subject-teacher`);
  return data;
}
async function downloadPDF(name){
  const {data} = await api.get(`/downloadPDF?name=${name}`, null,{responseType: 'arraybuffer'});
  return data;
}

export default {
  Login,
  getAllClassesForTeacher,
  changeClassesForTeacher,
  getAllStudentsForTeacher,
  userInfo,
  getClassesTeacher,
  getCompetencesFromUser,
  getObservationsFromUser,
  getUserById,
  getSubjects,
  getAllCompetences,
  getComponents,
  getRubrics,
  getRubricsCategories,
  postRubric,
  getRubric,
  createLinks,
  postLevelsRubric,
  exportClass,
  exportStudent,
  deletedObservation,
  postTeachers,
  postStudents,
  getSchools,
  getObservations,
  getStudents,
  getSubjectss,
  getUsers,
  getRubricss,
  getTeachers,
  getCompetences,
  getAllComponents,
  getLevels,
  testEditValues,
  getAnsweredRubric,
  getSchoolClass,
  getRelateClasses,
  postRelateClasses,
  deleteRelateClasses,
  editRelateClasses,
  getSubjectTeacher,
  createPDFforLinks,
  downloadPDF,
};
